import React, { Component } from 'react';
import { I18n } from "aws-amplify";
import DetectRTC from 'detectrtc';
import {ReactComponent as DownArrow} from './icons/down-arrow.svg'; 
import shareIcon from './icons/share.png'; 

class InstallPrompt extends Component { 
    isIOS(){
        return DetectRTC.osName==='iOS';
    } 
    isAndroid(){
        return DetectRTC.osName==='Android';
    }
    isFacebookBrowser(){
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }
    isInstagramBrowser(){
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("Instagram") > -1);
    }
    render() {
        return (
            <div className={"InstallPrompt " + (this.isIOS()?'IOS ':'') + (this.isAndroid()?'Android':'')}>
                {this.isIOS()&&(this.isFacebookBrowser()||this.isInstagramBrowser())?
                    <div>
                        <p>{this.isFacebookBrowser()?I18n.get('IOSFacebookMessage1'):""}{this.isInstagramBrowser()?I18n.get('IOSInstagramMessage1'):""}<br/>{I18n.get('IOSFacebookMessage2')}<br/>{I18n.get('IOSFacebookMessage3')}</p>
                    </div>
                :""}
                {this.isIOS()&&!(this.isFacebookBrowser()||this.isInstagramBrowser())?
                    <div>
                        <p>{I18n.get('IOSInstallMessage11')}<img src={shareIcon} alt="share" className="shareIcon"></img>{I18n.get('IOSInstallMessage12')}<br/>{I18n.get('IOSInstallMessage2')}<br/>{I18n.get('IOSInstallMessage3')}</p>
                        <DownArrow className="downarrow" alt={I18n.get('install')}></DownArrow>
                    </div>
                :""}
                 {this.isAndroid()&&(this.isFacebookBrowser()||this.isInstagramBrowser())?
                    <div>
                        <p>{this.isFacebookBrowser()?I18n.get('AndroidFacebookMessage1'):""}{this.isInstagramBrowser()?I18n.get('AndroidInstagramMessage1'):""}<br/>{I18n.get('AndroidFacebookMessage2')}<br/>{I18n.get('AndroidFacebookMessage3')}</p>
                        
                    </div>
                :""}
                {this.isAndroid()&&!(this.isFacebookBrowser()||this.isInstagramBrowser())?
                    <div>
                        <p>{I18n.get('AndroidInstallMessage1')}<br/>{I18n.get('AndroidInstallMessage2')}<br/>{I18n.get('AndroidInstallMessage3')}</p>
                        
                    </div>
                :""}

            </div>
           );
    }
}
export default InstallPrompt