import ReactGA from "react-ga4";


export function trackSignUp(country,email) {
  console.log("sending conversion for sign up ",country,email);
  window.gtag('event', 'conversion', {'send_to': 'AW-346065192/CTyzCJSZgckCEKiSgqUB'});
  ReactGA.event({
    category: 'SignUp',
    action: country
  });
  //ReactGA.pageview("/virtual/signedUp");
  ReactGA.send({ hitType: "pageview", page: "/virtual/signedUp", title: "Sign up" });

  window.fbq('init', '950196802426464',{em:email});
  window.fbq('track', 'CompleteRegistration');
  var userProperties =  {
    email: email,
    BROWSERLOCALE: navigator.language || navigator.userLanguage,
    USERAGENT:navigator.userAgent,
    COUNTRY:country
  };

  window.sendinblue.track(
    'SignUp' /*mandatory*/,
    userProperties
  );
  console.log("sent event to sib",userProperties);
  window.ttq.track('CompleteRegistration');
}
export function trackSignIn(email,userId){
  console.log("sending conversion for sign in ")
  ReactGA.set({ userId: userId });
}
export function trackPlayedSound(mode,textLocale,browserLocale,words,trialUntil,email){
  console.log("sending conversion for played sound",mode,textLocale,browserLocale,words,trialUntil)
  window.gtag('event', 'conversion', {'send_to': 'AW-346065192/iJ4JCKPxgtgCEKiSgqUB'});
  window.ttq.track('ViewContent');
  window.fbq('init', '950196802426464',{em:localStorage.getItem('user')});
  window.fbq('trackCustom', 'PlaySound', {mode: mode,
                                  textLocale:textLocale,
                                  browserLocale:browserLocale,
                                  foreignLocale: browserLocale&&textLocale!==browserLocale.substring(0,2),
                                  words: words,
                                  trailUntil:trialUntil?new Date(trialUntil).toISOString():""
                                });
  const modeKey = 'USED_MODE_' + mode.toUpperCase();
  var userProperties =  {
    [modeKey]: "TRUE",
    email: email,
    BROWSERLOCALE: browserLocale,
    USERAGENT:navigator.userAgent,
    PLAYSOUNDID: Date.now(),
    LOCALE:textLocale,
    WORDS:words,
    DATE: new Date().toISOString()
  };
  if(trialUntil){
    userProperties.TRIAL_UNTIL_DATE = trialUntil;
  }
  if(browserLocale&&textLocale!==browserLocale.substring(0,2)){
    userProperties.HAS_USED_FOREIGN_LANGUAGE = "TRUE";
  }
  if(words>50){
    userProperties.HAS_READ_LONG_TEXT = "TRUE";
  }
  var eventData = {
    mode: mode,
    textLocale:textLocale,
    browserLocale:browserLocale,
    words:words
  };
  window.sendinblue.track(
    'PlayedSound' /*mandatory*/,
    userProperties,
    eventData
  );
  if(window.Tawk_API){
    console.log("sending event to tawk",eventData)
    window.Tawk_API.addEvent('playedSound',eventData, function(error){});
  }

  console.log("sent event to sib",userProperties,eventData);
 ReactGA.gtag('event', 'purchase', {items:[{
    item_name: textLocale,
    item_id: mode.toUpperCase() + '-' +textLocale, 
    item_category: mode.toUpperCase(),
    price: words,
    quantity: words}],
    value:words
  });
  var numberOfReads = (localStorage.getItem("numberOfReads")?parseInt(localStorage.getItem("numberOfReads")):0)
  ReactGA.gtag('event', 'read', {
    event_category: 'ReadCount',
    event_label: numberOfReads + "",
    value: numberOfReads
  });
                        
}

export function trackTutorialView(action,progress,name){
  ReactGA.event({
    category: 'Tutorial',
    action: action,
    label:name,
    value:parseInt(progress)
  });
}
export function trackUnsubscribe(id,email){
  var userProperties =  {
    'SUBSCRIBED': 'UNSUBSCRIBED'
  };
  window.sendinblue.track(
    'Unsubscribed' /*mandatory*/,
    userProperties
  );
  ReactGA.gtag('event', 'Unsubscribe', {
    event_label: id
  });

}  

