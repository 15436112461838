import React from 'react'

import * as tf from '@tensorflow/tfjs'
import adapter from 'webrtc-adapter';

const MODEL_URL = process.env.PUBLIC_URL + '/model_web/'
const LABELS_URL = MODEL_URL + 'labels.json'
const MODEL_JSON = MODEL_URL + 'model.json'
var lastCalledTime;
var fps = 0;


const TFWrapper = model => {
  const calculateMaxScores = (scores, numBoxes, numClasses) => {
    const maxes = []
    const classes = []
    for (let i = 0; i < numBoxes; i++) {
      let max = Number.MIN_VALUE
      let index = -1
      for (let j = 0; j < numClasses; j++) {
        if (scores[i * numClasses + j] > max) {
          max = scores[i * numClasses + j]
          index = j
        }
      }
      maxes[i] = max
      classes[i] = index
    }
    return [maxes, classes]
  }

  const buildDetectedObjects = (
    width,
    height,
    boxes,
    scores,
    indexes,
    classes
  ) => {
    const count = indexes.length
    const objects = []
    for (let i = 0; i < count; i++) {
      const bbox = []
      for (let j = 0; j < 4; j++) {
        bbox[j] = boxes[indexes[i] * 4 + j]
      }
      const minY = bbox[0] * height
      const minX = bbox[1] * width
      const maxY = bbox[2] * height
      const maxX = bbox[3] * width
      bbox[0] = minX
      bbox[1] = minY
      bbox[2] = maxX - minX
      bbox[3] = maxY - minY
      objects.push({
        bbox: bbox,
        class: classes[indexes[i]],
        score: scores[indexes[i]]
      })
    }
    return objects
  }

  const detect = input => {
    const batched = tf.tidy(() => {
      const img = tf.browser.fromPixels(input)
      // Reshape to a single-element batch so we can pass it to executeAsync.
      return img.expandDims(0)
    })

    const height = batched.shape[1]
    const width = batched.shape[2]

    return model.executeAsync(batched).then(result => {
      const scores = result[0].dataSync()
      const boxes = result[1].dataSync()

      // clean the webgl tensors
      batched.dispose()
      tf.dispose(result)

      const [maxScores, classes] = calculateMaxScores(
        scores,
        result[0].shape[1],
        result[0].shape[2]
      )

      const prevBackend = tf.getBackend()
      // run post process in cpu
      tf.setBackend('cpu')
      const indexTensor = tf.tidy(() => {
        const boxes2 = tf.tensor2d(boxes, [
          result[1].shape[1],
          result[1].shape[3]
        ])
        return tf.image.nonMaxSuppression(
          boxes2,
          maxScores,
          20, // maxNumBoxes
          0.5, // iou_threshold
          0.90 // score_threshold
        )
      })
      const indexes = indexTensor.dataSync()
      indexTensor.dispose()
      // restore previous backend
      tf.setBackend(prevBackend)

      return buildDetectedObjects(
        width,
        height,
        boxes,
        maxScores,
        indexes,
        classes
      )
    })
  }
  return {
    detect: detect
  }
}

class FingerTracker extends React.Component {
  videoRef = React.createRef()
  canvasRef = React.createRef()


  componentDidMount() {
    if(this.props.predictionTreshhold){
      this.predictionTreshhold = this.props.predictionTreshhold
    } else {
      this.predictionTreshhold =0.9;
    }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
						width: { ideal: 1024 },
            			facingMode: 'environment'
               }
        })
        .then(stream => {
          window.stream = stream
          this.videoRef.current.srcObject = stream
          return new Promise((resolve, _) => {
            this.videoRef.current.onloadedmetadata = () => {
              console.log("first video resolved")
              this.canvasRef.current.width=this.videoRef.current.videoWidth;
              this.canvasRef.current.height=this.videoRef.current.videoHeight;
              console.log("Setting canvas to w:",this.canvasRef.current.width,"h:",this.canvasRef.current.height)
              var viewport = document.querySelector("meta[name=viewport]");
              viewport.setAttribute('content', '  height=' + this.videoRef.current.videoHeight);
              resolve()
            }
          })
      }).catch(e =>{
        //try without ideal width (old androids)
        navigator.mediaDevices
        .getUserMedia({
        audio: false,
        video: {
                facingMode: 'environment'
            }
        }).then(stream => {
          window.stream = stream
          this.videoRef.current.srcObject = stream
          return new Promise((resolve, _) => {
            this.videoRef.current.onloadedmetadata = () => {
              console.log("first video resolved")
              this.canvasRef.current.width=this.videoRef.current.videoWidth;
              this.canvasRef.current.height=this.videoRef.current.videoHeight;
              console.log("Setting canvas to w:",this.canvasRef.current.width,"h:",this.canvasRef.current.height)
              var viewport = document.querySelector("meta[name=viewport]");
              viewport.setAttribute('content', '  height=' + this.videoRef.current.videoHeight);
              resolve()
            }
          })  
        }).catch(e =>{
            alert("Cannot get access to camera. " + e.message);
            window.ga('send', 'event', 'JavaScript', 'error', 'Cannot get access to camera. Message:' + e.message +" Name:"+ e.name  +"Browser:" + adapter.browserDetails.browser +" Version " + adapter.browserDetails.version);
        })
      })


      const modelPromise = tf.loadGraphModel(MODEL_JSON)
      const labelsPromise = fetch(LABELS_URL).then(data => data.json())
      Promise.all([modelPromise, labelsPromise, webCamPromise])
        .then(values => {
          const [model, labels] = values
          console.log("labels:",labels)
          this.detectFrame(this.videoRef.current, model, labels)
          this.props.onVideoReady();
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
 
  detectFrame = (video, model, labels) => {

    TFWrapper(model)
      .detect(video)
      .then(predictions => {
        this.renderPredictions(predictions, labels,fps)
        requestAnimationFrame(() => {
          if(!lastCalledTime) {
            lastCalledTime = Date.now();
            fps = 0;
          } else {

            var delta = (Date.now() - lastCalledTime)/1000;
            lastCalledTime = Date.now();
            fps = 1/delta;
            //console.log("videoresolution for detection: "+ video.videoWidth + "x" + video.videoHeight)
          }
          this.detectFrame(video, model, labels)
        })
      })
  }

  renderPredictions = (predictions, labels, fps) => {
    if(!this.canvasRef||!this.canvasRef.current){
      //there is no canvas. probably in handheld mode. just silently return
      return
    }
    this.canvasRef.current.width = this.videoRef.current.videoWidth;
    this.canvasRef.current.height = this.videoRef.current.videoHeight;
    //console.log("Setting canvas to w:",this.canvasRef.current.width,"h:",this.canvasRef.current.height)
  
    const ctx = this.canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    if(document.getElementById("dataImage")){
      document.getElementById("dataImage").style.zIndex=1;
      document.getElementById("dataImage").src="";
    } 
    const font = '16px sans-serif'
    fps = ~~fps + 'fps'
    // Draw the fps background.
    ctx.fillStyle = '#a4d5e0'
    const textWidth = ctx.measureText(fps).width
    const textHeight = parseInt(font, 10) // base 10
    ctx.fillRect(0, 0, textWidth + 30, textHeight + 10)
    ctx.fillStyle = 'black'
    //console.log('fps:',fps);
    if (true) {

      // Font options.
      ctx.font = font
      ctx.textBaseline = 'top'
      predictions.forEach(prediction => {
        const label = labels[parseInt(prediction.class)]
        if(true){
          const x = prediction.bbox[0]
          const y = prediction.bbox[1]
          const width = prediction.bbox[2]
          const height = prediction.bbox[3]
          if(x<20||y<20||x+width>this.canvasRef.current.width-20||y+height>this.canvasRef.current.height-20){
            // the point is close to the edge so skip
          } else {
            // Draw the bounding box.
            ctx.strokeStyle = '#a4d5e0'
            ctx.lineWidth = 4
            ctx.strokeRect(x, y, width, height)
            // Draw the label background.
            ctx.fillStyle = '#a4d5e0'
            const textWidth = ctx.measureText(label).width
            const textHeight = parseInt(font, 10) // base 10
            ctx.fillRect(x, y, textWidth + 4, textHeight + 4)
          }
        }
        
      })

      predictions.forEach(prediction => {
        const label = labels[parseInt(prediction.class)]
        const x = prediction.bbox[0]
        const y = prediction.bbox[1]
        const width = prediction.bbox[2]
        const height = prediction.bbox[3]
        const score = prediction.score;
        // Draw the text last to ensure it's on top.
        ctx.fillStyle = '#000000'
        
        if(x<20||y<20||x+width>this.canvasRef.current.width-20||y+height>this.canvasRef.current.height-20){
          // the point is close to the edge so skip
          //ctx.fillText("edge", x, y)
        } else {
          ctx.fillText(label, x, y)
          if(label==='fingertip'){
            if( prediction.score>this.predictionTreshhold){
              this.props.onPoint({x: x + width*0.5,y:y + height,score:score});
            }
            
          } 
        }
        
      })
    }
    // Draw the text last to ensure it's on top.
    ctx.fillText(fps , 10, 5)
  }

  render() {
    return (
      <div>
        <video
          className="size"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          id="videoForDisplay"
        />
        <canvas
          className="size"
          ref={this.canvasRef}
          id="canvas"
        />
      </div>
    )
  }
}
export default FingerTracker
