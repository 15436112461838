import { Translations } from "@aws-amplify/ui-components";
const stage = process.env.REACT_APP_STAGE&&process.env.REACT_APP_STAGE==='beta'?" BETA":""
export const strings = {
    en: {
      signup_name_label: 'Full Name',
      signup_name_placeholder: 'Please enter your full name',
      signup_password_placeholder: 'Please choose a password',
      confirm_signup_email_label: "Email Address",
      confirm_signup_code_label: "Verification code (sent to your email)",
      confirm_signup_email_placeholder: "Enter email address",
      confirm_signup_code_placeholder: "Enter code",
      page_label: 'Page',
      paragraph_label: 'Paragraph',
      handheld_label: 'Handheld',
      tripod_label: 'Tripod',
      menu_label: 'More',
      trialEnded: 'Your free trial period has ended.',
      subscriptionFreeTrial:"You can try IcanRead for free for 10 days. After that you need a paid subscription.",
      subscriptionFreeTrialCTA: "Try it for free now",
      subscriptionNotActive:'Your subscription is not active',
      subscribeDescription: 'But don’t worry, you’re just 60 seconds away from levelling the playing field again.',
      perMonth: '/month',
      subscribe : 'Subscribe',
      defaultLanguage:'Default language:',
      watchTutorials:'Watch tutorials:',
      startReading: 'Start reading',
      startReadingNow: 'Start reading and watch tutorials later',
      tutorial:'tutorial',
      learnHowToUse: "Learn how to use IcanRead:",
      IGetIt:"Ok, I get it. Let's go!",
      IOSInstallMessage11:"INSTALL IcanRead by clicking",
      IOSInstallMessage12:"below,",
      IOSInstallMessage2:"select 'Add to Home Screen'",
      IOSInstallMessage3:"further down in the list.",
      IOSFacebookMessage1:"You are still in the Facebook app. Tap",
      IOSInstagramMessage1:"You are still in the Instagram app. Tap",
      IOSFacebookMessage2:"the 3 dot menu in top right and select",
      IOSFacebookMessage3:"Open in Safari.",
      AndroidInstallMessage1:"INSTALL IcanRead by tapping",
      AndroidInstallMessage2:"the 3 dot menu in top right and select",
      AndroidInstallMessage3:"Add to Home screen.",
      AndroidFacebookMessage1:"You are still in the Facebook app. Tap",
      AndroidInstagramMessage1:"You are still in the Instagram app. Tap",
      AndroidFacebookMessage2:"the 3 dot menu in top right and select",
      AndroidFacebookMessage3:"Open in Chrome.",
      CameraError1:"IcanRead did not get access to the camera.",
      CameraError2:"If you didn't get a question to allow it, go to:",
      CameraErrorIOS3:"Settings, Safari, Camera and set it to Ask or Allow",
      CameraErrorAndroid3:"Settings, Applications, Application Manager, Chrome, Permissions, Camera and allow",
      CameraError4:"and try again",
      en:'English',
      freeTrialUntil: 'Free trial until:',
      subscriptionIsActive:'Your subscription is ACTIVE',
      selectAPaidPlan:'Select a paid plan',
      unsubscribe:'Cancel your subscription here',
      speedometer_label: 'Speed',
      showWords: 'Show word by word',
      [Translations.SIGN_IN_HEADER_TEXT]: "Log in to IcanRead" + stage,
      [Translations.SIGN_UP_HEADER_TEXT]: "Create an account to IcanRead" + stage,
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "You've got mail!",
      [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Verification code (sent to your email)",
      [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "A verification code has been sent to your email. Lost your code?",
      desktopMessage: 'Icanread is a mobile web app. Please use your mobile, open Chrome or Safari, go to www.icanread.io and log in again. There is also a desktop app that can be downloaded ',
      here:'here',
      downloadLangcode:'en',
      screenModeMessageMac:'Point BELOW any text with your mouse and press Command+i',
      screenModeMessageWindows:'Point BELOW any text with your mouse and press CTRL+i',
      screenModeMessageMouseMac:'Press Command+i and drag with your mouse to select text',
      screenModeMessageMouseWindows:'Press CTRL+i and drag with your mouse to select text',
      screenMouseSelectMode:'Mouse selection mode'
    },
    sv: {
      signup_name_label: 'Namn',
      signup_name_placeholder: 'Fyll i för- och efternamn',
      signup_password_placeholder: 'Välj ett lösenord',
      confirm_signup_email_label: "Epostadress",
      confirm_signup_code_label: "Verifieringskod (skickad till din epost)",
      confirm_signup_email_placeholder: "Ange Epostadress",
      confirm_signup_code_placeholder: "Ange kod",
      page_label: 'Sida',
      paragraph_label: 'Stycke',
      handheld_label: 'Handhållen',
      tripod_label: 'Stativ',
      menu_label: 'Mer',
      trialEnded: 'Din prövoperiod är avslutad.',
      subscriptionFreeTrial:"Du kan testa IcanRead gratis i 10 dagar. Efter det behöver du en prenumeration.",
      subscriptionFreeTrialCTA: "Testa gratis nu",
      subscriptionNotActive:'Din prenumeration är inte aktiv',
      subscribeDescription: 'Men oroa dig inte, det tar dig bara en minut att åter få tillgång till IcanRead.',
      perMonth: '/månad',
      subscribe : 'Prenumerera',
      defaultLanguage:'Förvalt språk:',
      watchTutorials:'Se instruktionsvideos:',
      startReadingNow: 'Börja läs och se instruktion senare',
      learnHowToUse: "Lär dig använda IcanRead:",
      startReading: 'Börja läsa',
      tutorial:'instruktion',
      IGetIt:"Ok, jag fattar. Nu kör vi!",
      IOSInstallMessage11:"INSTALLERA IcanRead, klicka på",
      IOSInstallMessage12:"nedan,",
      IOSInstallMessage2:"och välj 'Lägg till på hemskärmen' ,",
      IOSInstallMessage3:"längre ner i listan",
      IOSFacebookMessage1:"Du är fortfarande i Facebooks app. Klicka",
      IOSFacebookMessage2:"de tre prickarna överst i menyn och välj",
      IOSFacebookMessage3:"Öppna i Safari.",
      AndroidInstallMessage1:"INSTALLERA IcanRead genom att klicka",
      AndroidInstallMessage2:"de 3 prickarna i högra hörnet och välj",
      AndroidInstallMessage3:"Lägg till på hemskärmen.",
      AndroidFacebookMessage1:"Du är fortfarande i Facobooks app. Klicka",
      AndroidFacebookMessage2:"de 3 prickarna i högra hörnet och välj",
      AndroidFacebookMessage3:"Öppna i Chrome.",
      CameraError1:"IcanRead fick inte tillgång till kameran.",
      CameraError2:"Om du inte själv avböjde, gå till:",
      CameraErrorIOS3:"Inställningar, Safari, Kamera och välj Fråga eller Tillåt",
      CameraErrorAndroid3:"Inställningar, Appar och aviseringar, Chrome, Behörigheter, Kamera och tillåt",
      CameraError4:"och försök igen",
      sv : 'Svenska',
      freeTrialUntil: 'Provoperioden avslutas:',
      subscriptionIsActive:'Din prenumeration är aktiv',
      selectAPaidPlan:'Välj en betald plan',
      unsubscribe:'Säg upp din prenumeration här',
      speedometer_label: 'Hastighet',
      showWords: 'Visa ord för ord',
      [Translations.SIGN_IN_HEADER_TEXT]: "Logga in till IcanRead" + stage,
      [Translations.SIGN_IN_ACTION]: "Logga in",
      [Translations.EMAIL_LABEL]: "E-post",
      [Translations.EMAIL_PLACEHOLDER]: "Fyll i din e-postadress",
      [Translations.PASSWORD_LABEL]: "Lösenord",
      [Translations.PASSWORD_PLACEHOLDER]: "Fyll i ditt lösenord",
      [Translations.FORGOT_PASSWORD_TEXT]: "Glömt ditt lösenord?",
      [Translations.RESET_PASSWORD_TEXT]: "Återställ lösenordet",
      [Translations.NO_ACCOUNT_TEXT]: "Inget konto?",
      [Translations.CREATE_ACCOUNT_TEXT]: "Skapa konto",
      [Translations.SIGN_UP_HEADER_TEXT]: "Skapa konto till IcanRead"+ stage,
      [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Skapa konto",
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Har du redan ett konto?",
      [Translations.SIGN_IN_TEXT]: "Logga in",
      [Translations.RESET_YOUR_PASSWORD]: "Återställ ditt lösenord",
      [Translations.SEND_CODE]: "Skicka kod",
      [Translations.BACK_TO_SIGN_IN]: "Tillbaka till inloggning",
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Du har fått ett mail!",
      [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Bekräftelsekod (som du fått på mail)",
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Fyll i koden",
      [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Ett mail har skickats med en bekräftelsekod. Förlorat din kod?",
      [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Skicka koden igen",
      [Translations.CONFIRM]: "Bekräfta",
      desktopMessage: 'Icanread är en mobilapp. Logga in på mobilen i en webbläsare. Det finns också ett program till dator som kan laddas ned ',
      here:'här',
      downloadLangcode:'sv',
      screenModeMessageMac:'Peka med musen under text på skärmen och tryck Command+i på tangentbordet',
      screenModeMessageWindows:'Peka med musen under text på skärmen och tryck CTRL+i på tangentbordet',
      screenModeMessageMouseMac:'Tryck Command+i och dra med musen för att välja text',
      screenModeMessageMouseWindows:'Tryck CTRL+i och dra med musen för att välja text',
      screenMouseSelectMode:'Musvalsläge'
    },
    es: {
      es:'Español',
      signup_name_label: 'Nombre',
      signup_name_placeholder: 'Escriba tu nombre completo',
      signup_password_placeholder: "Escoge una contraseña",
      confirm_signup_email_label: "Email Address",
      confirm_signup_code_label: "Code",
      confirm_signup_email_placeholder: "Enter email address",
      confirm_signup_code_placeholder: "Enter code",
      page_label: 'Página',
      paragraph_label: 'Párrafo',
      handheld_label: 'A mano',
      tripod_label: ' Trípode',
      speedometer_label: 'Velocidad',
      showWords: 'Show word by word',
      menu_label: 'Más',
      trialEnded: 'Your free trial period has ended.',
      subscriptionFreeTrial:"You can try IcanRead for free for 10 days. After that you need a paid subscription.",
      subscriptionFreeTrialCTA: "Try it for free now",
      subscriptionNotActive:'Your subscription is not active',
      subscribeDescription: 'But don’t worry, you’re just 60 seconds away from levelling the playing field again.',
      perMonth: '/month',
      subscribe : 'Subscribe',
      defaultLanguage:'Default language:',
      watchTutorials:'Vea los tutoriales:',
      startReading: 'Start reading',
      startReadingNow: 'Start reading and watch tutorials later',
      learnHowToUse: "Learn how to use IcanRead:",
      tutorial:'tutorial',
      IGetIt:"Ok, lo tengo. Vamonos!",
      IOSInstallMessage11:"INSTALL IcanRead by clicking",
      IOSInstallMessage12:"below,",
      IOSInstallMessage2:"scroll down and select",
      IOSInstallMessage3:"Add to Home Screen.",
      IOSFacebookMessage1:"You are still in the Facebook app. Tap",
      IOSFacebookMessage2:"the 3 dot menu in top right and select",
      IOSFacebookMessage3:"Open in Safari.",
      AndroidInstallMessage1:"INSTALL IcanRead by tapping",
      AndroidInstallMessage2:"the 3 dot menu in top right and select",
      AndroidInstallMessage3:"Add to Home screen.",
      AndroidFacebookMessage1:"You are still in the Facebook app. Tap",
      AndroidFacebookMessage2:"the 3 dot menu in top right and select",
      AndroidFacebookMessage3:"Open in Chrome.",
      CameraError1:"IcanRead did not get access to the camera.",
      CameraError2:"If you didn't get a question to allow it, go to:",
      CameraErrorIOS3:"Settings, Safari, Camera and set it to Ask or Allow",
      CameraErrorAndroid3:"Settings, Applications, Application Manager, Chrome, Permissions, Camera and allow",
      CameraError4:"and try again",
      en:'English',
      freeTrialUntil: 'Free trial until:',
      subscriptionIsActive:'Your subscription is ACTIVE',
      selectAPaidPlan:'Select a paid plan',
      unsubscribe:'Cancel your subscription here',
      [Translations.SIGN_IN_HEADER_TEXT]: "Inicia sesión en tu cuenta" + stage,
      [Translations.SIGN_IN_ACTION]: "Iniciar sesión",
      [Translations.EMAIL_LABEL]:"E-mail",
      [Translations.EMAIL_PLACEHOLDER]: "Ingresa tu dirección de E-mail",
      [Translations.CREATE_ACCOUNT_TEXT]: "Regístrate", 
      [Translations.PASSWORD_LABEL]: "Contraseña", 
      [Translations.PASSWORD_PLACEHOLDER]: "Ingresa tu contraseña", 
      [Translations.FORGOT_PASSWORD_TEXT]: "¿Olvidaste tu contraseña?",
      [Translations.RESET_PASSWORD_TEXT]: "Reiniciar contraseña",
      [Translations.NO_ACCOUNT_TEXT]: "¿No tienes una cuenta?",
      [Translations.CREATE_ACCOUNT_TEXT]: "Crea una cuenta", 
      [Translations.SIGN_UP_HEADER_TEXT]: "Crear cuenta para IcanRead"+ stage,
      [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Crea una cuenta", 
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "¿Tenes cuenta?",
      [Translations.SIGN_IN_TEXT]: "Iniciar sesión",
      [Translations.RESET_YOUR_PASSWORD]:"Reiniciar Contraseña",
      [Translations.SEND_CODE]: "Enviar código",
      [Translations.BACK_TO_SIGN_IN]: "Volver al inicio de sesion",
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmar la cuenta",
      [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Codigo de verificacion",
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Ingresa el código recibido en E-mail.",
      [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "¿Perdiste tu código?",
      [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Reenviar código",
      [Translations.CONFIRM]: "Confirmar",
      desktopMessage: 'Icanread is a mobile web app. Please log in on your mobile in the browser. There is also a desktop app that can be downloaded ',
      here:'here',
      downloadLangcode:'en',
      screenModeMessageMac:'Point at any text with your mouse and press Command+i',
      screenModeMessageWindows:'Point at any text with your mouse and press CTRL+i',
      screenModeMessageMouseMac:'Press Command+i and drag with your mouse to select text',
      screenModeMessageMouseWindows:'Press CTRL+i and drag with your mouse to select text',
      screenMouseSelectMode:'Mouse selection mode'
    }, 
    de: {
      signup_name_label: 'Name',
      signup_name_placeholder: 'Geben Sie den Vor- und Nachnamen ein',
      signup_password_placeholder: 'Wählen sie ein Passwort',
      confirm_signup_email_label: "Email Address",
      confirm_signup_code_label: "Code",
      confirm_signup_email_placeholder: "Enter email address",
      confirm_signup_code_placeholder: "Enter code",
      page_label: 'Seite',
      paragraph_label: 'Absatz',
      handheld_label: 'Tragbar',
      tripod_label: 'Stativ',
      speedometer_label: 'Geschwindigkeit',
      showWords: 'Show word by word',
      menu_label: 'Mehr',
      trialEnded: 'Your free trial period has ended.',
      subscriptionFreeTrial:"You can try IcanRead for free for 10 days. After that you need a paid subscription.",
      subscriptionFreeTrialCTA: "Try it for free now",
      subscriptionNotActive:'Your subscription is not active',
      subscribeDescription: 'But don’t worry, you’re just 60 seconds away from levelling the playing field again.',
      perMonth: '/month',
      subscribe : 'Subscribe',
      defaultLanguage:'Default language:',
      watchTutorials:'Sehen Sie Videotutorials:',
      startReadingNow: 'Start reading and watch tutorials later',
      learnHowToUse: "Learn how to use IcanRead:",
      startReading: 'Start reading',
      tutorial:'tutorial',
      IGetIt:"Ich verstehe ja. Gehen wir!",
      IOSInstallMessage11:"INSTALL IcanRead by clicking",
      IOSInstallMessage12:"below,",      IOSFacebookMessage1:"You are still in the Facebook app. Tap",
      IOSFacebookMessage2:"the 3 dot menu in top right and select",
      IOSFacebookMessage3:"Open in Safari.",
      AndroidInstallMessage1:"INSTALL IcanRead by tapping",
      AndroidInstallMessage2:"the 3 dot menu in top right and select",
      AndroidInstallMessage3:"Add to Home screen.",
      AndroidFacebookMessage1:"You are still in the Facebook app. Tap",
      AndroidFacebookMessage2:"the 3 dot menu in top right and select",
      AndroidFacebookMessage3:"Open in Chrome.",
      CameraError1:"IcanRead did not get access to the camera.",
      CameraError2:"If you didn't get a question to allow it, go to:",
      CameraErrorIOS3:"Settings, Safari, Camera and set it to Ask or Allow",
      CameraErrorAndroid3:"Settings, Applications, Application Manager, Chrome, Permissions, Camera and allow",
      CameraError4:"and try again",
      en:'English',
      freeTrialUntil: 'Free trial until:',
      subscriptionIsActive:'Your subscription is ACTIVE',
      selectAPaidPlan:'Select a paid plan',
      unsubscribe:'Cancel your subscription here',
      'Sign In': 'Anmelden',
      'Sign Up': 'Registrieren',
      [Translations.EMAIL_LABEL]:"E-mail",
      [Translations.EMAIL_PLACEHOLDER]: "Geben Sie Ihren E-mail ein",
      'Sign Out': 'Abmelden',
      'Sign in to your account': 'Melden Sie sich mit Ihrem Account an',
      Username: 'Benutzername',
      Password: 'Passwort',
      'Enter your username': 'Geben Sie Ihren Benutzernamen ein',
      'Enter your password': 'Geben Sie Ihr Passwort ein',
      'No account?': 'Kein Account? ',
      'Forgot your password?': 'Passwort vergessen? ',
      'Reset password': 'Passwort zurücksetzen',
      'User does not exist': 'Dieser Benutzer existiert nicht',
      'User already exists': 'Dieser Benutzer existiert bereits',
      'Incorrect username or password':
        'Falscher Benutzername oder falsches Passwort',
      'Invalid password format': 'Ungültiges Passwort-Format',
      'Create account': 'Hier registrieren',
      'Forgot Password': 'Passwort vergessen',
      'Change Password': 'Passwort ändern',
      'New Password': 'Neues Passwort',
      Email: 'Email',
      'Phone Number': 'Telefonnummer',
      'Confirm a Code': 'Code bestätigen',
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Anmeldung bestätigen",
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Geben Sie der Bestätigungs-Code ein",
      'Confirm Sign Up': 'Registrierung bestätigen',
      'Back to Sign In': 'Zurück zur Anmeldung',
      'Send Code': 'Code senden',
      Confirm: 'Bestätigen',
      'Resend Code': 'Code erneut senden',
      Submit: 'Abschicken',
      Skip: 'Überspringen',
      Verify: 'Verifizieren',
      'Verify Contact': 'Kontakt verifizieren',
      Code: 'Code',
      'Confirmation Code': 'Bestätigungs-Code',
      'Lost your code?': 'Code verloren? ',
      'Account recovery requires verified contact information':
        'Zurücksetzen des Account benötigt einen verifizierten Account',
      'Invalid phone number format': `Ungültiges Telefonummern-Format.
              Benutze eine Nummer im Format: +12345678900`,
      'Create Account': 'Account erstellen',
      'Have an account?': 'Schon registriert? ',
      'Sign in': 'Anmelden',
      'Create a new account': 'Erstelle einen neuen Account',
      'Reset your password': 'Zurücksetzen des Passworts',
      'An account with the given email already exists.':
        'Ein Account mit dieser Email existiert bereits.',
      'Username cannot be empty': 'Benutzername darf nicht leer sein',
      'Password attempts exceeded':
        'Die maximale Anzahl der fehlerhaften Anmeldeversuche wurde erreicht',
        desktopMessage: 'Icanread is a mobile web app. Please log in on your mobile in the browser. There is also a desktop app that can be downloaded ',
        here:'here',
        downloadLangcode:'en',
        screenModeMessageMac:'Point at any text with your mouse and press Command+i',
        screenModeMessageWindows:'Point at any text with your mouse and press CTRL+i',
        screenModeMessageMouseMac:'Press Command+i and drag with your mouse to select text',
        screenModeMessageMouseWindows:'Press CTRL+i and drag with your mouse to select text',
        screenMouseSelectMode:'Mouse selection mode'
    },
    fr: {
      signup_name_label: 'Nom',
      signup_name_placeholder: 'Saisissez votre nom complet',
      signup_password_placeholder: 'Sélectionnez un mot de passe',
      confirm_signup_email_label: "Email Address",
      confirm_signup_code_label: "Code",
      confirm_signup_email_placeholder: "Enter email address",
      confirm_signup_code_placeholder: "Enter code",
      page_label: 'Page',
      paragraph_label: 'Paragraphe',
      handheld_label: 'À main',
      tripod_label: 'Trépied',
      showWords: 'Show word by word',
      speedometer_label: 'Vitesse',
      menu_label: 'Plus',
      trialEnded: 'Your free trial period has ended.',
      subscriptionFreeTrial:"You can try IcanRead for free for 10 days. After that you need a paid subscription.",
      subscriptionFreeTrialCTA: "Try it for free now",
      subscriptionNotActive:'Your subscription is not active',
      subscribeDescription: 'But don’t worry, you’re just 60 seconds away from levelling the playing field again.',
      perMonth: '/month',
      subscribe : 'Subscribe',
      defaultLanguage:'Default language:',
      watchTutorials:'Regardez nos tutoriels:',
      startReading: 'Start reading',
      tutorial:'tutoriel',
      startReadingNow: 'Start reading and watch tutorials later',
      learnHowToUse: "Learn how to use IcanRead:", 
      IGetIt:"D'accord. On y va!",
      IOSInstallMessage11:"INSTALL IcanRead by clicking",
      IOSInstallMessage12:"below,",
      IOSInstallMessage2:"scroll down and select",
      IOSInstallMessage3:"Add to Home Screen.",
      IOSFacebookMessage1:"You are still in the Facebook app. Tap",
      IOSFacebookMessage2:"the 3 dot menu in top right and select",
      IOSFacebookMessage3:"Open in Safari.",
      AndroidInstallMessage1:"INSTALL IcanRead by tapping",
      AndroidInstallMessage2:"the 3 dot menu in top right and select",
      AndroidInstallMessage3:"Add to Home screen.",
      AndroidFacebookMessage1:"You are still in the Facebook app. Tap",
      AndroidFacebookMessage2:"the 3 dot menu in top right and select",
      AndroidFacebookMessage3:"Open in Chrome.",
      CameraError1:"IcanRead did not get access to the camera.",
      CameraError2:"If you didn't get a question to allow it, go to:",
      CameraErrorIOS3:"Settings, Safari, Camera and set it to Ask or Allow",
      CameraErrorAndroid3:"Settings, Applications, Application Manager, Chrome, Permissions, Camera and allow",
      CameraError4:"and try again",
      en:'English',
      freeTrialUntil: 'Free trial until:',
      subscriptionIsActive:'Your subscription is ACTIVE',
      selectAPaidPlan:'Select a paid plan',
      unsubscribe:'Cancel your subscription here',
      'Sign In': 'Se connecter',
      'Sign In with Amazon': 'Se connecter avec Amazon',
      'Sign In with Facebook': 'Se connecter avec Facebook',
      'Sign In with Google': 'Se connecter avec Google',
      'Sign in with AWS': 'Se connecter avec AWS',
      'Sign Up': "S'inscrire",
      'Sign Out': 'Déconnexion',
      'Forgot Password': 'Mot de passe oublié',
      Username: "Nom d'utilisateur",
      Password: 'Mot de passe',
      'Change Password': 'Modifier le mot de passe',
      Change: 'Modifier',
      'New Password': 'Nouveau mot de passe',
      [Translations.EMAIL_LABEL]:"Email",
      [Translations.EMAIL_PLACEHOLDER]: "Saisissez votre Email",
      Email: 'Email',
      'Phone Number': 'Numéro de téléphone',
      'Confirm a Code': 'Confirmer un code',
      'Confirm Sign In': 'Confirmer la connexion',
      'Confirm Sign Up': "Confirmer l'inscription",
      'Back to Sign In': 'Retour à la connexion',
      Send: 'Envoyer',
      'Send Code': "M'envoyer un code",
      Confirm: 'Confirmer',
      SMS: 'SMS',
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmer le compte",
      'Confirm SMS Code': 'Confirmer le code SMS',
      'Confirm TOTP Code': 'Confirmer le code TOTP',
      'Resend a Code': 'Renvoyer un code',
      Submit: 'Soumettre',
      Skip: 'Passer',
      Verify: 'Vérifier',
      'Verify Contact': 'Vérifier le contact',
      Code: 'Code',
      'Confirmation Code': 'Code de confirmation',
      'Account recovery requires verified contact information':
        'La récupération du compte nécessite des informations de contact vérifiées',
  
      'User does not exist': "L'utilisateur n'existe pas",
      'User already exists': "L'utilisateur existe déjà",
      'Incorrect username or password': 'Identifiant ou mot de passe incorrect',
      'Invalid password format': 'Format de mot de passe invalide',
      'Invalid phone number format': `Format de numéro de téléphone invalide. Veuillez utiliser un format +12345678900`,
      'Username/client id combination not found.': "L'utilisateur n'existe pas",
      'Network error': 'Erreur réseau',
      'Sign in to your account': 'Connexion à votre compte',
      'Forgot your password?': 'Mot de passe oublié ? ',
      'Reset password': 'Réinitialisez votre mot de passe',
      'No account?': 'Pas de compte ? ',
      'Create account': 'Créer un compte',
      'Create Account': 'Créer un compte',
      'Have an account?': 'Déjà un compte ? ',
      'Sign in': 'Se connecter',
      'Create a new account': 'Créer un nouveau compte',
      'Reset your password': 'Réinitialisez votre mot de passe',
      'Enter your username': "Saisissez votre nom d'utilisateur",
      'Enter your password': 'Saisissez votre mot de passe',
      'Enter your phone number': 'Saisissez votre numéro de téléphone',
      'Enter your email': 'Saisissez votre adresse email',
      'Enter your code': 'Saisissez votre code de confirmation',
      'Lost your code?': 'Vous avez perdu votre code ? ',
      'Resend Code': 'Renvoyer le code',
      or: 'ou',
      'An account with the given email already exists.':
        'Un utilisateur avec cette adresse email existe déjà.',
      'Username cannot be empty': "Le nom d'utilisateur doit être renseigné",
      desktopMessage: 'Icanread is a mobile web app. Please log in on your mobile in the browser. There is also a desktop app that can be downloaded ',
      here:'here',
      downloadLangcode:'en',
      screenModeMessageMac:'Point at any text with your mouse and press Command+i',
      screenModeMessageWindows:'Point at any text with your mouse and press CTRL+i',
      screenModeMessageMouseMac:'Press Command+i and drag with your mouse to select text',
      screenModeMessageMouseWindows:'Press CTRL+i and drag with your mouse to select text',
      screenMouseSelectMode:'Mouse selection mode'
    },
    it: {
      signup_name_label: 'Nome',
      signup_name_placeholder: 'Inserisci il tuo nome e cognome',
      signup_password_placeholder: 'Scegli una password',
      confirm_signup_email_label: "Email Address",
      confirm_signup_code_label: "Code",
      confirm_signup_email_placeholder: "Enter email address",
      confirm_signup_code_placeholder: "Enter code",
      page_label: 'Pagina',
      paragraph_label: 'Paragrafo',
      handheld_label: 'Palmare',
      tripod_label: 'Treppiedi',
      speedometer_label: 'Velocità',
      showWords: 'Show word by word',
      menu_label: 'Altro',
      trialEnded: 'Your free trial period has ended.',
      subscriptionFreeTrial:"You can try IcanRead for free for 10 days. After that you need a paid subscription.",
      subscriptionFreeTrialCTA: "Try it for free now",
      subscriptionNotActive:'Your subscription is not active',
      subscribeDescription: 'But don’t worry, you’re just 60 seconds away from levelling the playing field again.',
      perMonth: '/month',
      subscribe : 'Subscribe',
      defaultLanguage:'Default language:',  
      watchTutorials:'Guarda i tutorial:',
      startReading: 'Start reading',
      startReadingNow: 'Start reading and watch tutorials later',
      learnHowToUse: "Learn how to use IcanRead:",
      tutorial:'tutorial',
      IGetIt:"Ok, ho capito. Andiamo!",
      IOSInstallMessage11:"INSTALL IcanRead by clicking",
      IOSInstallMessage12:"below,",
      IOSInstallMessage2:"scroll down and select",
      IOSInstallMessage3:"Add to Home Screen.",
      IOSFacebookMessage1:"You are still in the Facebook app. Tap",
      IOSFacebookMessage2:"the 3 dot menu in top right and select",
      IOSFacebookMessage3:"Open in Safari.",
      AndroidInstallMessage1:"INSTALL IcanRead by tapping",
      AndroidInstallMessage2:"the 3 dot menu in top right and select",
      AndroidInstallMessage3:"Add to Home screen.",
      AndroidFacebookMessage1:"You are still in the Facebook app. Tap",
      AndroidFacebookMessage2:"the 3 dot menu in top right and select",
      AndroidFacebookMessage3:"Open in Chrome.",
      CameraError1:"IcanRead did not get access to the camera.",
      CameraError2:"If you didn't get a question to allow it, go to:",
      CameraErrorIOS3:"Settings, Safari, Camera and set it to Ask or Allow",
      CameraErrorAndroid3:"Settings, Applications, Application Manager, Chrome, Permissions, Camera and allow",
      CameraError4:"and try again",
      en:'English',
      freeTrialUntil: 'Free trial until:',
      subscriptionIsActive:'Your subscription is ACTIVE',
      selectAPaidPlan:'Select a paid plan',
      unsubscribe:'Cancel your subscription here',
      'Account recovery requires verified contact information':
        'Ripristino del conto richiede un account verificati',
      'An account with the given email already exists.':
        'Un account con questa email esiste già.',
      'Back to Sign In': 'Torna alla Accesso',
      'Change Password': 'Cambia la password',
      Code: 'Codice',
      Confirm: 'Conferma',
      [Translations.EMAIL_LABEL]:"Email",
      [Translations.EMAIL_PLACEHOLDER]: "Inserisci la tua Email",
      'Confirm Sign In': 'Conferma di applicazione',
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Registrazione Conferma",
      'Confirm a Code': 'Codice Conferma',
      'Confirmation Code': 'Codice di verifica',
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Inserisci il codice di verifica",
      'Create Account': 'Crea account',
      'Create a new account': 'Creare un nuovo account',
      'Create account': 'Registrati',
      Email: 'E-mail',
      'Enter your password': 'Inserisci la password',
      'Enter your username': 'Inserisci il tuo nome utente',
      'Forgot your password?': 'Password dimenticata?',
      'Forgot Password': 'Password dimenticata',
      'Have an account?': 'Già registrato?',
      'Incorrect username or password': 'Nome utente o password errati',
      'Invalid password format': 'Formato della password non valido',
      'Invalid phone number format': 'Utilizzo non valido Telefonummern formattare un numero nel formato :. 12.345.678,9 mille',
      'Lost your code?': 'Perso codice?',
      'New Password': 'Nuova password',
      'No account?': 'Nessun account?',
      Password: 'Password',
      'Password attempts exceeded': 'Il numero massimo di tentativi di accesso falliti è stato raggiunto',
      'Phone Number': 'Numero di telefono',
      'Resend Code': 'Codice Rispedisci',
      'Reset password': 'Ripristina password',
      'Reset your password': 'Resetta password',
      'Send Code': 'Invia codice',
      'Sign In': 'Accesso',
      'Sign Out': 'Esci',
      'Sign Up': 'Iscriviti',
      'Sign in': 'Accesso',
      'Sign in to your account': 'Accedi con il tuo account',
      Skip: 'Salta',
      Submit: 'Sottoscrivi',
      'User already exists': 'Questo utente esiste già',
      'User does not exist': 'Questo utente non esiste',
      Username: 'Nome utente',
      'Username cannot be empty': 'Nome utente non può essere vuoto',
      Verify: 'Verifica',
      'Verify Contact': 'Contatto verifica',
      desktopMessage: 'Icanread is a mobile web app. Please log in on your mobile in the browser. There is also a desktop app that can be downloaded ',
      here:'here',
      downloadLangcode:'en',
      screenModeMessageMac:'Point at any text with your mouse and press Command+i',
      screenModeMessageWindows:'Point at any text with your mouse and press CTRL+i',
      screenModeMessageMouseMac:'Press Command+i and drag with your mouse to select text',
      screenModeMessageMouseWindows:'Press CTRL+i and drag with your mouse to select text',
      screenMouseSelectMode:'Mouse selection mode'
    },
    // zh: {
    //   'Sign In': '登录',
    //   'Sign Up': '注册',
    //   'Sign Out': '退出',
    //   'Forgot Password': '忘记密码',
    //   Username: '用户名',
    //   Password: '密码',
    //   'Change Password': '改变密码',
    //   'New Password': '新密码',
    //   Email: '邮箱',
    //   'Phone Number': '电话',
    //   'Confirm a Code': '确认码',
    //   'Confirm Sign In': '确认登录',
    //   'Confirm Sign Up': '确认注册',
    //   'Back to Sign In': '回到登录',
    //   'Send Code': '发送确认码',
    //   Confirm: '确认',
    //   'Resend a Code': '重发确认码',
    //   Submit: '提交',
    //   Skip: '跳过',
    //   Verify: '验证',
    //   'Verify Contact': '验证联系方式',
    //   Code: '确认码',
    //   'Account recovery requires verified contact information':
    //     '账户恢复需要验证过的联系方式',
  
    //   'User does not exist': '用户不存在',
    //   'User already exists': '用户已经存在',
    //   'Incorrect username or password': '用户名或密码错误',
    //   'Invalid password format': '密码格式错误',
    //   'Invalid phone number format': '电话格式错误，请使用格式 +12345678900',
    // },
    // ja: {
    //   'Sign In': 'サインイン ',
    //   'Sign Up': '登録 ',
    //   'Sign Out': 'サインアウト ',
    //   'Sign in to your account': 'アカウントにサインイン ',
    //   Username: 'ユーザー名 ',
    //   Password: 'パスワード ',
    //   'Enter your username': 'ユーザー名を入力 ',
    //   'Enter your password': 'パスワードを入力 ',
    //   'No account? ': 'アカウントが無いとき ',
    //   'Forgot your password?': 'パスワードを忘れましたか？ ',
    //   'Reset password': 'パスワードをリセット ',
    //   'User does not exist': 'ユーザーが存在しません ',
    //   'User already exists': '既にユーザーが存在しています ',
    //   'Incorrect username or password': 'ユーザー名かパスワードが異なります ',
    //   'Invalid password format': 'パスワードの形式が無効です ',
    //   'Create account': 'アカウントを作る ',
    //   'Forgot Password': 'パスワードを忘れた ',
    //   'Change Password': 'パスワードを変える ',
    //   'New Password': '新しいパスワード',
    //   Email: 'メールアドレス',
    //   'Phone Number': '電話番号',
    //   'Confirm a Code': 'コードを確認',
    //   'Confirm Sign In': 'サインインする',
    //   'Confirm Sign Up': '登録する',
    //   'Back to Sign In': 'サインインに戻る',
    //   'Send Code': 'コードを送信',
    //   Confirm: '確定',
    //   'Resend Code': 'コードを再送信',
    //   Submit: '送信',
    //   Skip: 'スキップ',
    //   Verify: '確認',
    //   'Verify Contact': '連絡先を確認',
    //   Code: 'コード',
    //   'Confirmation Code': '確認コード',
    //   'Lost your code?': 'コードを失くしましたか？',
    //   'Account recovery requires verified contact information':
    //     'アカウントの復旧には確認済みの連絡先が必要です',
    //   'Invalid phone number format':
    //     '不正な電話番号の形式です。\n+12345678900 の形式で入力してください',
    //   'Create Account': 'アカウントを作る',
    //   'Have an account?': 'アカウントを持っていますか？',
    //   'Sign in': 'サインイン',
    //   'Create a new account': '新しいアカウントを作る',
    //   'Reset your password': 'パスワードをリセットする',
    //   'An account with the given email already exists.':
    //     '入力されたメールアドレスのアカウントが既に存在します',
    //   'Username cannot be empty': 'ユーザー名は入力必須です',
    //   'Password attempts exceeded': 'サインインの試行回数が上限に達しました',
    //   'Sign In with Google': 'Googleでサインイン',
    //   'Sign In with Facebook': 'Facebookでサインイン',
    // },
  };