
export async function createStripeCheckoutSession(priceId,userEmail,userId,onSuccess,onError) {
    var startTime = Date.now();
    var stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
    var url = "";
    switch(stage) {
      case "dev":
        url = "https://0q331f5n0b.execute-api.eu-west-1.amazonaws.com/dev/create-checkout-session";
        break;
      case "beta":
        url = "https://zybgmcp8nl.execute-api.eu-west-1.amazonaws.com/beta/create-checkout-session";
        break;
      case "prod":
        url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/create-checkout-session";
        break;  
      default:
        url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/create-checkout-session";
        break;
    };
    console.log("sending request to: " +url);
      window.$.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        crossDomain: 'true',
        data: JSON.stringify({ 'priceId': priceId,
                'userEmail':userEmail,
                'userId': userId,
              }),
        contentType:"application/json; charset=utf-8",    
        complete: function(xhr, textStatus) {
        // Request complete.
        var completedTime = Date.now();
        console.log("request complete. Total time: " + (completedTime - startTime) + 'ms');
        },
        // Request was successful.
        success: onSuccess,
        error: onError
      });
 }
