import React, { Component } from 'react'; 
import { I18n } from "aws-amplify";
import { strings } from '../helpers/strings';
import { loadStripe } from '@stripe/stripe-js';
import {createStripeCheckoutSession} from '../helpers/stripeService';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51HRyUiHk63dm2rEHzXnf4cWS0naWpbgB3TjjNaKbplCFIK85jVeuVLgOygNWAdC3100grFr1OZcOleBb7DiKp8Os00orW8lq45');
//const stripePromise = loadStripe('pk_test_51HRyUiHk63dm2rEHCRmM8xRpxvXVr71FQgvAql9vrYtYF3UUB6v2qBudwXf4FcriB6xbeuJA5CPUXMnqDjHTHCW100pElrCiKW');


I18n.putVocabularies(strings);

class SubscribeModule extends Component {
    constructor(props) {
        super(props);
        this.state={
            subscriptionOffers:{
            sv_SE:[
                {  offerName:'Månadpris',
                    offerPrice:'149kr',
                    duration:'månad',
                    offerPriceId:'price_1J0sJEHk63dm2rEHA5uUxONa',
                    offerDescription:{__html:'Du betalar per månad och kan när som helst avsluta ditt abonnemang.'},
                },
                {   offerName:'6 månader',
                    offerMark:'Populär',
                    offerPrice:'749kr',
                    duration: 'halvår',
                    offerPriceId:'price_1J0sJEHk63dm2rEHhiL5eN8C',
                    offerDescription:{__html:'Du betalar för 6 månader och sparar 145 kr jämfört med månadspriset.'}
                },
                {   offerName:'1 år',
                    offerMark:'Billigast',
                    offerPrice:'1249kr',
                    duration: 'år',
                    offerPriceId:'price_1J0sJEHk63dm2rEHxwN5yOxc',
                    offerDescription:{__html:'Du får IcanRead ett helt år för endast 1249kr. Du sparar hela 539kr jämfört med månadspriset.'}
                }
            ],    
            en_SE:[
                {  offerName:'Monthly',
                    offerPrice:'149 SEK',
                    duration:'month',
                    offerPriceId:'price_1J0sJEHk63dm2rEHA5uUxONa',
                    //Test price
                    //offerPriceId:'price_1HRyauHk63dm2rEHh79ozYBv',
                    offerDescription:{__html:'You pay monthly and can cancel your subscription at any time.'},
                },
                {   offerName:'6 months',
                    offerMark:'Popular',
                    offerPrice:'749 SEK',
                    duration: '6 months',
                    offerPriceId:'price_1J0sJEHk63dm2rEHhiL5eN8C',
                    offerDescription:{__html:'You pay for 6 months and save $14 as compared to the monthly price'}
                },
                {   offerName:'1 year',
                    offerPrice:'1249 SEK',
                    duration: 'year',
                    offerMark:'Best value',
                    offerPriceId:'price_1J0sJEHk63dm2rEHxwN5yOxc',
                    offerDescription:{__html:'You get IcanRead for a full year for just 1249 SEK. You save 539SEK compared with the monthly price.'}
                }
            ],
            SE:[
                {  offerName:'Månadpris',
                    offerPrice:'149kr',
                    duration:'månad',
                    offerPriceId:'price_1J0sJEHk63dm2rEHA5uUxONa',
                    offerDescription:{__html:'Du betalar per månad och kan när som helst avsluta ditt abonemang.'},
                },
                {   offerName:'6 månader',
                    offerMark:'Populär',
                    offerPrice:'749kr',
                    duration: 'halvår',
                    offerPriceId:'price_1J0sJEHk63dm2rEHhiL5eN8C',
                    offerDescription:{__html:'Du betalar för 6 månader och sparar 145 kr jämfört med månadspriset.'}
                },
                {   offerName:'1 år',
                    offerMark:'Billigast',
                    offerPrice:'1249kr',
                    duration: 'år',
                    offerPriceId:'price_1J0sJEHk63dm2rEHxwN5yOxc',
                    offerDescription:{__html:'Du får IcanRead ett helt år för endast 1249kr. Du sparar hela 539kr jämfört med månadspriset.'}
                }
                ],
            US:[
                {  offerName:'Monthly',
                    offerPrice:'$14.99',
                    duration:'month',
                    offerPriceId:'price_1J0sJEHk63dm2rEHVHwq5IlC',
                    offerDescription:{__html:'You pay monthly and can cancel your subscription at any time.'},
                },
                {   offerName:'6 months',
                    offerMark:'Popular',
                    offerPrice:'$74.99',
                    duration:'6 months',
                    offerPriceId:'price_1J0sJEHk63dm2rEHmtBaft4A',
                    offerDescription:{__html:'You pay for 6 months and save $14 as compared to the monthly price'}
                },
                {   offerName:'1 year',
                    offerPrice:'$124.99',
                    duration:'year',
                    offerMark:'Best value',
                    offerPriceId:'price_1J0sJEHk63dm2rEHbLLBiYE6',
                    offerDescription:{__html:'You get IcanRead for a full year for just $124.99. You save $54 compared with the monthly price.'}
                }
            ],
            ZA:[
                {  offerName:'Monthly',
                    offerPrice:'R199',
                    duration:'month',
                    offerPriceId:'price_1JXjgEHk63dm2rEHOFgbwn6q',
                    offerDescription:{__html:'You pay monthly and can cancel your subscription at any time.'},
                },
                {   offerName:'6 months',
                    offerMark:'Popular',
                    offerPrice:'R990',
                    duration:'6 months',
                    offerPriceId:'price_1JXjh2Hk63dm2rEH8EdlNSyb',
                    offerDescription:{__html:'You pay for 6 months and save R204 as compared to the monthly price'}
                },
                {   offerName:'1 year',
                    offerPrice:'R1749',
                    duration:'year',
                    offerMark:'Best value',
                    offerPriceId:'price_1JXjhtHk63dm2rEH942Kd7vX',
                    offerDescription:{__html:'You get IcanRead for a full year for just R1749. You save R639 compared with the monthly price.'}
                }
            ],
            default:[
                {  offerName:'Monthly',
                    offerPrice:'$14.99',
                    duration:'month',
                    offerPriceId:'price_1J0sJEHk63dm2rEHVHwq5IlC',
                    offerDescription:{__html:'You pay monthly and can cancel your subscription at any time.'},
                },
                {   offerName:'6 months',
                    offerMark:'Popular',
                    offerPrice:'$74.99',
                    duration:'6 months',
                    offerPriceId:'price_1J0sJEHk63dm2rEHmtBaft4A',
                    offerDescription:{__html:'You pay for 6 months and save $14 as compared to the monthly price'}
                },
                {   offerName:'1 year',
                    offerPrice:'$124.99',
                    duration:'year',
                    offerMark:'Best value',
                    offerPriceId:'price_1J0sJEHk63dm2rEHbLLBiYE6',
                    offerDescription:{__html:'You get IcanRead for a full year for just $124.99. You save $54 compared with the monthly price.'}
                }
            ]
        }
        }  
    }
    async handleClick(priceId){
        // When the customer clicks on the button, redirect them to Checkout.
        console.log("subscribing to",priceId)
        createStripeCheckoutSession(priceId,this.props.userEmail,this.props.userId,
        async function(response, textStatus, xhr) {
            //call successfully callback
            const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            sessionId : response.sessionId
        });
        if(error){
            this.setState({stripeError: error.message})
            console.log("Error from Stripe",error)
        }
        },
        function(xhr, textStatus, err) {
            //error in call
            console.log('Error' , err);
            console.log('Error text: ' + textStatus);
            throw new Error(textStatus);
        });
    }

    render() {
        var userLanguage;
        if(navigator.language && navigator.language.length>=2){
            userLanguage = navigator.language.substring(0,2) + "_"
        } else if(navigator.userLanguage && navigator.userLanguage.length>=2){
            userLanguage = navigator.userLanguage.substring(0,2) +  "_"
        }
        console.log("looking for offer:",userLanguage + this.props.userCountry,"found",this.state.subscriptionOffers[userLanguage + this.props.userCountry]);
        console.log("looking for offer:",this.props.userCountry,"found",this.state.subscriptionOffers[this.props.userCountry]);
        var offers = this.state.subscriptionOffers[userLanguage + this.props.userCountry]||this.state.subscriptionOffers[this.props.userCountry]||this.state.subscriptionOffers.default;
        //make the page scrollable (to avoid scrollbars in normal operation the page is not scrollable normally)
        document.body.style.overflow = "visible"
        var offerNumber =1;
        return (
            <div className="Subscribe">   
            {this.props.freeTrialEnded?
            <div><div>{I18n.get('trialEnded')}</div><div>{I18n.get('subscribeDescription')}</div></div>
            :
            (this.props.trialUntil&&this.props.trialUntil<new Date()?<div><div>{I18n.get('subscriptionNotActive')}</div><div>{I18n.get('subscribeDescription')}</div></div>:
            <div>
                <div>{I18n.get('subscriptionFreeTrial')}</div>
                <div className="SubscribeButton" onClick={this.props.onTrailButtonClick}>
                    {I18n.get('subscriptionFreeTrialCTA')}
                </div> 
            </div>   
            )
            }
            {offers.map((offer,index) => {
                return <div className="Offer" key={index}>
                    {offer.offerMark?<div className={"OfferMark _" + offerNumber++} >{offer.offerMark}</div>:""}
                    <div className="OfferName" >{offer.offerName}</div>
                    <div className="OfferPrice" >
                        <div className="Price">{offer.offerPrice}</div>
                        <div className="Peroid"> / {offer.duration}</div>
                    </div>
                    <div className="OfferDescription" dangerouslySetInnerHTML={offer.offerDescription} >
                    </div>
                    <div>{this.state.stripeError?
                    this.state.stripeError.message:""}</div>
                    <div className="SubscribeButton" role="link" onClick={this.handleClick.bind(this,offer.offerPriceId)}>
                    {I18n.get('subscribe')}
                    </div>
                </div>      
            })
            }  
        </div>);
    }
}
export default SubscribeModule