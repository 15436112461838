import React, { Component } from 'react';
import './App.css';
import TextReader from './modules/TextReader';
import '@aws-amplify/ui/dist/style.css';
import { AmplifyAuthenticator,AmplifyAuthContainer,AmplifySignUp,AmplifySignIn,AmplifyConfirmSignUp} from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import ReactGA from "react-ga4";
import { I18n } from "aws-amplify";
import { trackSignUp, trackSignIn } from './helpers/conversionTracking';

import { strings } from './helpers/strings';

import { Hub } from 'aws-amplify';


Amplify.configure(aws_exports);
//default to english
var displayLanguage = 'en';
var browserLanguage = navigator.language || navigator.userLanguage;
Object.keys(strings).forEach(supportedDisplayLanguage => {
    if(browserLanguage&&browserLanguage.substring(0,2)===supportedDisplayLanguage){
        displayLanguage = supportedDisplayLanguage;
    }
})
I18n.setLanguage(displayLanguage);
I18n.putVocabularies(strings);


class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize('G-EBB68TDH78');
    if(localStorage.getItem('username')){
      ReactGA.set({ user_id: localStorage.getItem('username') });
    } 
    //ReactGA.pageview('/');
    ReactGA.send({ hitType: "pageview", page: "/", title: "Icanread app" });
    Hub.listen('auth', (data) => {
      console.log("Auth event",data.payload);
      ReactGA.event({
        category: 'Auth',
        action: data.payload.event,
        label: data.payload.data&&data.payload.data.message?data.payload.data.message:""
      });
      if("signUp"===data.payload.event){ 
        trackSignUp(sessionStorage.getItem("countryCode"),data.payload.data.user.username)
      }
      if("signIn"===data.payload.event){
        trackSignIn(data.payload.data.attributes.email,data.payload.data.username)
        localStorage.setItem("user",data.payload.data.attributes.email)
        localStorage.setItem("username",data.payload.data.username) 
      }
    });
  } 
  render() {
    var initialState = localStorage.getItem("hasBeenLoggedIn")==="1"?"signin":(window.location.hash==="#verify"?"confirmSignUp":"signup")
    return (
      <div className="App">
        <AmplifyAuthContainer>
          <AmplifyAuthenticator usernameAlias="email" initialAuthState={initialState}>
          {initialState==="confirmSignUp"?<AmplifyConfirmSignUp slot="confirm-sign-up"
            formFields={[
              {
                label: I18n.get('confirm_signup_email_label'),
                placeholder: I18n.get('confirm_signup_email_placeholder'),
                type: "username",
                required: true,
                handleInputChange: (event, cb) => {
                  cb(event);
                }
              },
              {
                label: I18n.get('confirm_signup_code_label'),
                placeholder: I18n.get('confirm_signup_code_placeholder'),
                type: "code",
                required: true,
                handleInputChange: (event, cb) => {
                  cb(event);
                }
              }
            ]}></AmplifyConfirmSignUp>:""}
            <AmplifySignUp 
            slot="sign-up"
            usernameAlias="email"  
            formFields={[
              {
                type: "email",
                required: true,
                handleInputChange: (event, cb) => {
                  event.srcElement.value = event.srcElement.value.toLowerCase().trim();
                  cb(event);
                }
              },
              {
                label: I18n.get('signup_name_label'),
                placeholder: I18n.get('signup_name_placeholder'),
                required: true, 
                type: 'name',
                handleInputChange: (event, cb) => {
                  cb(event);
                }
              },
              {
                type: "password",
                required: true,
                placeholder: I18n.get('signup_password_placeholder'),
                handleInputChange: (event, cb) => {
                  cb(event);
                }
              }
            ]}
            ></AmplifySignUp>
            <AmplifySignIn
            slot="sign-in"
            usernameAlias="email"   
            
            formFields={[
              {
                type: "email",
                required: true,
                handleInputChange: (event, cb) => {
                  event.srcElement.value = event.srcElement.value.toLowerCase().trim();
                  cb(event);
                }
              },
              {
                type: "password",
                required: true,
                handleInputChange: (event, cb) => {
                  cb(event);
                }
              }
            ]}
            >
            </AmplifySignIn>
            <TextReader/>
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
      </div>
    );
  }
}

export default App;
