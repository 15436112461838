import React, { Component } from 'react';
import StableFingerTracker from './StableFingerTracker';
import Tutorial from './Tutorial';
import InstallPrompt from './InstallPrompt';
import ClickTracker from './ClickTracker';
import Menu from './Menu';
import SubscribeModule from './SubscribeModule';
import TextDetectionParser from '../helpers/TextDetectionParser';
import ReactGA from "react-ga4";
import { getAudioFromBackend,getNarrationAudioFromBackend } from '../helpers/backendService';
import playbutton from './icons/play-button.svg'
import DetectRTC from 'detectrtc';
import { Auth } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { trackPlayedSound,trackUnsubscribe } from '../helpers/conversionTracking';

class TextReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: "https://raw.githubusercontent.com/anars/blank-audio/master/1-second-of-silence.mp3",
      audioUnlocked: false,
      pendingRequests: 0,
      playingSound: false,
      currentSound: [],
      stoppingSound: false,
      audioContext: null,
      markedText: [],
      markedPoint:null,
      lastFrameTime: 0,
      mode: 'none',
      speed:(localStorage.getItem("speed")?localStorage.getItem("speed"):1),
      showWords:false,
      countryCode:null,
      userIsEligable:true,
      freeTrialEnded:false,
      showSubscribeModule:false,
      language:null,
      activeSubscription:false,
      lastMode: (localStorage.getItem("lastMode")?localStorage.getItem("lastMode"):'handheld'),
      showDarkScreen: true,
      rowSelectionMode: (localStorage.getItem("rowSelectionMode")?localStorage.getItem("rowSelectionMode"):'paragraph'),
      readState: "none",
      showTutorial: (localStorage.getItem("seenTutorials")?!localStorage.getItem("seenTutorials"):true),
      tutorialMode: 'None',
      isInWebAppiOS:(window.navigator.standalone === true),
      isInWebAppChrome:(window.matchMedia('(display-mode: standalone)').matches),
      readArea: null,
      screenMouseSelectMode : (localStorage.getItem("screenMouseSelectMode")?localStorage.getItem("screenMouseSelectMode"):false),
      mouseSelectState:false
    };
    DetectRTC.load(function() {
      // eslint-disable-next-line
      this.state = Object.assign(this.state, {hasWebcamAccess : window.api||DetectRTC.hasWebcam});
      // eslint-disable-next-line
      this.state = Object.assign(this.state, {isIOS : DetectRTC.osName==='iOS'}); 
    }.bind(this)) 
  }
    reloadTimer;
    initialize(){
      console.log("initilizing TextReader")
      var isStripeCancelPage = (window.location.hash==="#SubscriptionCancel");
      if(isStripeCancelPage){
        this.setState({showSubscribeModule:true});
        this.getUserData()
      }
      //Get the country the user is in
      var countryCode = sessionStorage.getItem("countryCode")
      if(!countryCode){
        fetch('https://ipapi.co/country/')
        .then(function(response) {
          response.text().then(function(txt){
            console.log("Country code from API call",txt);
            this.setState({countryCode:txt});
            sessionStorage.setItem("countryCode",txt);
          }.bind(this));
        }.bind(this))
        .catch(function(error) {
          console.log(error)
        });
      } else {
        console.log("Country code",countryCode);
        this.setState({countryCode:countryCode});
      }  
      var language = navigator.language || navigator.userLanguage;
      this.setState({language : language})
      console.log("in init of TextReader")
      if(window.api){

          //TODO: find out why this does not always work. I had one case where the first time i logged in i got the "normal" mode

          // this means we are in an electron window
          this.setState({lastMode : "screen"})
          //disable tutorils until we have desktop tutorials ready
          this.setState({showTutorial : false})
          //Start automatically
          this.onPlayClick(null,"screen");
          if(localStorage.getItem("readAreaMode")){
            this.setState({readArea: JSON.parse(localStorage.getItem("readArea"))})
          }
          


          window.navigator.mediaDevices.getDisplayMedia = () => {
            return new Promise(async (resolve, reject) => {
              try {
                console.log("polyfill called")
                const sources = await window.api.getMainScreenSource()
                console.log("sources",sources);
                const stream = await window.navigator.mediaDevices.getUserMedia({
                  audio: false,
                  video: {
                    mandatory: {
                      chromeMediaSource: 'desktop',
                      chromeMediaSourceId: sources[0].id,
                      maxFrameRate: 5
                    }
                  }
                })
                resolve(stream)
          
              } catch (err) {
                console.error('Error displaying desktop capture sources:', err)
                reject(err)
              }
            })
          }
          
      }
    }

    handleError(err){
      try {
        this.setState({pendingRequests:0});
        this.setState({playingSound:false});
        this.setState({readState:"none"});
        this.setState({markedText:[]})
        this.setState({currentWord:null})
        if(window.api){
          window.api.send("normalWindow",[])
        }
        this.setState({markedPoint:null})
        ReactGA.event({
          category: 'JavaScript',
          action: 'Error',
          label: err.message + ' Name : ' + err.name?err.name:""
        });
        if(document.getElementById("dataImage")){
          document.getElementById("dataImage").style.zIndex=1;
          console.log("Hiding dataImage")
          document.getElementById("dataImage").src="";
      }    
        console.log('Error: ' + err);
      } catch(e) {
      }
    }
    unLockWebAudio(){
      //set hasBeenLoggedIn to 1 so that the user can be shown the login next time it expires instead of the sign up
      localStorage.setItem("hasBeenLoggedIn","1");
      if(!this.state.audioUnlocked){
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var context = new AudioContext();
        this.setState({audioContext: context}); 
        context.resume();
        // create empty buffer
        var buffer = context.createBuffer(1, 1, 22050);
        var source = context.createBufferSource();
        source.buffer = buffer;

        // connect to output (your speakers)
        source.connect(context.destination);

        // play the file
        if (source.start) {
          source.start(0);
        } else if (source.play) {
          source.play(0);
        } else if (source.noteOn) {
          source.noteOn(0);
        }
        console.log("sound unlocked");
        this.setState({audioUnlocked:true});
        console.log(this)
      }
    }
    playNarration(narration){
      console.log("playing Narration", narration)
      var startTime = Date.now();
      var that = this;
      getNarrationAudioFromBackend(narration,'en', 
      function(response, textStatus, xhr) {
        try{
          var responseTime = Date.now();
          console.log("Response of narration request. time: " + (responseTime - startTime) + 'ms');
          if (response.status_code === 200 ) {
            
          

            const base64Audio = response.audio;
            const binaryAudio = Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0));
            console.log("binarydata prepared")
            console.log(that)
            that.state.audioContext.decodeAudioData(binaryAudio.buffer,function(buffer){
              try{
                console.log("binarydata decoded")
                that.play(buffer,null,true);
              } catch (err){
                that.handleError(err);
              }  
            },
            function(err){ 
              that.handleError(err);
            }
            );
            
          } else {
            console.log("Narration request didn not return with status code 200",response.status_code)
          }
      } catch(err){
        that.handleError(err);
      } 
      },function(xhr, textStatus, err) {
        that.handleError(err);
        console.log('Error' , err);
        console.log('Error text: ' + textStatus);
      })
    }
    play(buffer,narrationToFollow,isNarration){
      
      console.log("playing buffer",narrationToFollow,isNarration);
      var that = this;
      if(!isNarration){
        this.stopPlayingSound();
      }
      that.setState({playingSound:true});
      that.setState({readState:"playing"});
      function endPlayingSound(event) {
        if(that.state.stoppingSound){
          //if the sound is stopped, that means another is starting and we shall not do anything
          that.setState({stoppingSound:false});
          that.setState({playingSound:false});
          that.setState({readState:"none"});
          console.log("Stopped sound ended")
          return
        }
        if(narrationToFollow){
          that.playNarration(narrationToFollow);
        } else{
          that.setState({playingSound:false});
          that.setState({readState:"none"});
          that.setState({currentSound:[]});
          console.log('playback finished with ' + event.type);
          that.setState({markedText:[]});
          that.setState({currentWord:null});
          that.setState({markedPoint:null})
          if(window.api){
            window.api.send("normalWindow",[])
          }
        }

        
      }
      try {
        const source = this.state.audioContext.createBufferSource();
        var sounds = that.state.currentSound;
        sounds.push(source);
        that.setState({currentSound:sounds});
        source.onended = endPlayingSound;
        source.onerror = endPlayingSound;
        //onsuspend = endPlayingSound;
        //onstalled = endPlayingSound;
          
        source.buffer = buffer;
        source.loop = false;
        source.connect(this.state.audioContext.destination);
        console.log("before start")
        var soundStartTime = that.state.audioContext.currentTime;
        if (source.start) {
          console.log("source start")
          source.start(that.state.audioContext.currentTime);
          that.setState({soundStartTime: soundStartTime });
        } else if (source.play) {
          console.log("source play")
          source.play(that.state.audioContext.currentTime);
          that.setState({soundStartTime: soundStartTime});
        }
        if(!isNarration){
          that.markTextOnCanvas(that.state.finalLines, that.state.lineMarks);
          //that.showWordsInFastReader(that.state.words,null,soundStartTime )
        }
        console.log("after start")
      } catch (error) {
        that.handleError(error);
      }
    }
  pauseSound(){
    var that = this;
    this.state.audioContext.suspend().then( () =>
      that.setState({ readState: "paused" ,
                    pauseTime: that.state.audioContext.currentTime - that.state.soundStartTime})
    );
    this.state.lineMarkTimers.map(timer => clearTimeout(timer))
    //this.state.wordTimers.map(timer => clearTimeout(timer))

    ReactGA.event({
      category: 'Read',
      action: 'Pause',
      label: Number.parseFloat(that.state.audioContext.currentTime - that.state.soundStartTime).toFixed(2)
    });

  }  
  playPausedSound(){
    var that = this;
    this.state.audioContext.resume().then( () =>
      that.setState({ readState: "playing" })
    );
    this.markTextOnCanvas(this.state.finalLines,this.state.lineMarks,this.state.pauseTime)
    //that.showWordsInFastReader(that.state.words,this.state.pauseTime*1000)

    ReactGA.event({
      category: 'Read',
      action: 'Resume',
      label: Number.parseFloat(this.state.pauseTime).toFixed(2)
    });
  }
  stopPlayingSound() {

    if (this.state.currentSound.length > 0) {
      //sound is already playing, stop before starting new one
      console.log("turning previous sounds off");
      this.state.currentSound.map(sound => sound.stop());
      this.setState({ currentSound: [] });
      this.setState({ stoppingSound: true });
      this.state.lineMarkTimers.map(timer => clearTimeout(timer))
    }
    else
    {
      console.log("no other sound playing PlayingSound: " + this.state.playingSound + " currentSound: " + this.state.currentSound);
    }
    if(this.state.readState==="paused"){
      this.playPausedSound()
    }
    
  }

    componentDidMount() {
      this.initialize();
    }

    async beep() {
      var audioCtx = this.state.audioContext; 
      var oscillator = audioCtx.createOscillator();
      var gainNode = audioCtx.createGain();
    
      oscillator.connect(gainNode);
      gainNode.connect(audioCtx.destination);
    
      gainNode.gain.value = 0.005  ;
      oscillator.frequency.value = 4000 ;
      oscillator.type = 'sawtooth';
    
      oscillator.start();

     await this.sleep(10);
     oscillator.stop();
    }

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) {
          // was able or not to get WebP representation
          return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      }
      // very old browser like IE 8, canvas not supported
      return false;
    }
  
    async getFrame(point,endPoint,xPos,fromElectron){
      console.log("fromElectron",fromElectron)
      var isSwipe = false;
      var isVertical = false;
      var isHorisontal = false;
      if(endPoint){
        isSwipe = true;
        isVertical = !!xPos;
        isHorisontal = point.y ===0;
        console.log("getFrame swipe", point,endPoint,xPos);
      }
      //restart the idle timer so that the page only reloads are 30 minutes of idle time
      this.resetReloadTimer();
      if((this.state.mode==='handheld'||this.state.mode==='screen')&&(this.state.markedText.length>0||this.state.markedPoint||this.state.playingSound)){
        // we are displaying something on the canvas. clear it so the user can click again.
        console.log("we are displaying something on the canvas and get click. clear it so the user can click again.")
        this.setState({markedPoint:null,markedText:[],finalLines:[],lineMarks:[]},() => { 
          //in handheld and screen mode we stop the active sound when the user clicks again. 
          // this is to make it easy to correct a mistake without having to listen to the faulty text
          if(this.state.playingSound){
            this.stopPlayingSound();
          }
          if(window.api){
            window.api.send("normalWindow",[])
          }

        })

        return
      }
      if(fromElectron&&!this.state.userIsEligable){
        // we are in an electron app and the user is not eligable. then do nothing.
        return
      }

      console.log("in getFrame",this.state.mode,point,endPoint,fromElectron);
      var dataUrl;
      if ((this.state.mode==='tripod'&&!this.state.pendingRequests>0&&!this.state.playingSound&&this.state.audioUnlocked)
            //In handheld we want current text to be interupted if user points again 
          ||(this.state.mode==='handheld'&&this.state.audioUnlocked)
          ||(this.state.mode==='screen'&&fromElectron)){ 
            console.log("getting frame")
         
        var d = new Date(); 
        this.setState({ lastFrameTime: d.getTime() })
        var canvas = document.querySelector("#canvas");
        var video = document.querySelector("#videoForDisplay");

        this.setState({markedPoint:(endPoint?{x:xPos?xPos:(endPoint.x+point.x)/2,y:Math.max(point.y,endPoint.y)}:point)})
        console.log("markedPoint",(endPoint?{x:xPos?xPos:(endPoint.x+point.x)/2,y:Math.max(point.y,endPoint.y)}:point));
        //if y<15 then there can not be any readable text above the point so no roundtrip needed
        if(point.y<15.0&&!endPoint){
          console.log('Aborting getAudio as y>15 y:' + point.y)
          this.setState({markedText:[]});
          if(window.api){
            window.api.send("normalWindow",[])
          }
          return
        } 
        await this.beep();
        var imageQuality = 0.8;
        if(this.state.mode==='tripod'){
          // when phone is in a tripod the image is upside down so image needs to be rotated 180 deg, and coordinates too
        
          // move to the center of the canvas  
          canvas.getContext('2d').translate(canvas.width/2,canvas.height/2);
          //rotate 180 degrees
          canvas.getContext('2d').rotate(Math.PI);
          //Shift image tocover canvas
          canvas.getContext('2d').drawImage(video,-canvas.width/2,  -canvas.height/2);

          //translate the point after the rotation
          point.x=canvas.width - point.x;
          point.y=canvas.height - point.y;
          
        } else{

          var viewportBox=canvas.getBoundingClientRect();
          var viewportX = Math.max(-viewportBox.left,0)
          var viewportY = Math.max(-viewportBox.top,0)
          var viewWidth = document.documentElement.clientWidth;
          var viewHeight = document.documentElement.clientHeight;
          console.log("viewportBox", viewportBox)
          console.log("viewportX:" + viewportX + "viewportY:" + viewportY)
          console.log("viewWidth:" + viewWidth + "viewHeight:" + viewHeight)
          
          if(this.state.mode==='handheld'){
            
            if(endPoint){
              // only get parts of the video inside swipe
              cover("#a4d5e0",point.x,point.y,(endPoint.x-point.x),(endPoint.y-point.y));
              //canvas.getContext('2d').drawImage(video,point.x,point.y,(endPoint.x-point.x),(endPoint.y-point.y),point.x,point.y,(endPoint.x-point.x),(endPoint.y-point.y));
              point.x = (xPos?xPos:(endPoint.x+point.x)/2);

              point.y = Math.max(endPoint.y,point.y);

            } else {
              //delay the capture 200ms to avoid a blury image due to the vibration from the tap
              console.log("Delaying image capture by 200ms")
              await this.sleep(200);
              canvas.getContext('2d').drawImage(video,0,0);
            }
          } else {
            //screen mode
            if(this.state.screenMouseSelectMode===false||this.state.screenMouseSelectMode==="false"){
              var bodyElement = window.document.querySelector("body");
              bodyElement.style.background= "rgba(255, 255, 255, 0.0)";
              bodyElement.style.display ="none";
            }   
            if(endPoint){
              // only get parts of the video inside readArea
              if(this.state.screenMouseSelectMode===false||this.state.screenMouseSelectMode==="false"){
                canvas.height = endPoint.y*1.1;
                canvas.getContext('2d').drawImage(video,point.x,point.y,(endPoint.x-point.x)*1.1,(endPoint.y-point.y)*1.1,point.x,point.y,(endPoint.x-point.x)*1.1,(endPoint.y-point.y)*1.1);
              } else {
                cover("#a4d5e0",point.x,point.y,(endPoint.x-point.x),(endPoint.y-point.y));
              }
              point.x = (xPos?xPos:(endPoint.x+point.x)/2);
              point.y = endPoint.y;
            } else {
              canvas.height = point.y*1.1;
              canvas.getContext('2d').drawImage(video,0,0,canvas.width,point.y*1.1,0,0,canvas.width,canvas.height);
            }
            
            if(window.api&&(this.state.screenMouseSelectMode===false||this.state.screenMouseSelectMode==="false")){
              window.api.send("fullScreenWindow",[])
            }  
            /* var opts = {
              colors: 16,             // desired palette size
              method: 2,               // histogram method, 2: min-population threshold within subregions; 1: global top-population
              boxSize: [128,128],        // subregion dims (if method = 2)
              boxPxls: 2,              // min-population threshold (if method = 2)
              initColors: 1024,        // # of top-occurring colors  to start with (if method = 1)
              minHueCols: 0,           // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
              dithKern: null,          // dithering kernel name, see available kernels in docs below
              dithDelta: 0,            // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
              dithSerp: false,         // enable serpentine pattern dithering
              palette: [],             // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
              reIndex: false,          // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
              useCache: true,          // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
              cacheFreq: 10,           // min color occurance count needed to qualify for caching
              colorDist: "euclidean",  // method used to determine color distance, can also be "manhattan"
          };
            function typeOf(val) {
              return Object.prototype.toString.call(val).slice(8,-1);
            }
            var q = new RgbQuant(opts);
            q.sample(canvas);
	          var pal = q.palette(true);
            var quantized = q.reduce(canvas);
            var idxi32 = new Uint32Array(quantized.buffer);
            var ctx = canvas.getContext('2d');
            var imgd = ctx.createImageData(canvas.width, canvas.height);
            if (typeOf(imgd.data) == "CanvasPixelArray") {
              var data = imgd.data;
              for (var i = 0, len = data.length; i < len; ++i)
                data[i] = quantized[i];
            }
            else {
              var buf32 = new Uint32Array(imgd.data.buffer);
              buf32.set(idxi32);
            }
          
            ctx.putImageData(imgd, 0, 0);  */
            
            if(canvas.height*canvas.width>(1024*700)){
              imageQuality = 0.3
            }
          }
          console.log("Capturing image");
        }
        var imageFormat = "image/jpeg"
        if (this.canUseWebP()){
          
          imageFormat ="image/webp"
        }
        dataUrl = canvas.toDataURL(imageFormat, imageQuality);
        // I dont like this but hey
        this.setState({repaintCrosshair:true});
        //Setting it back to false to rerender just once
        this.setState({repaintCrosshair:false});

        // reading the localstorage for showWords here so that it can be change in A/B test 
        this.setState({showWords:(localStorage.getItem("showWords")?localStorage.getItem("showWords"):false)})
        var i = new Image(); 
        i.onload = function(){
          console.log("dataUrl resolution: " +  i.width+", "+i.height );
          console.log("dataUrl size: " +  dataUrl.length * 2/1000 + "kB"); //2 bytes per character
          console.log("dataUrl quality: " +  imageQuality);
          console.log("dataUrl format: " + imageFormat)
        };
        i.src = dataUrl; 

        ReactGA.event({
          category: 'Read',
          action: 'SendRequest',
          label: "Language: "+(navigator.language || navigator.userLanguage) + " isSwipe: " + isSwipe+ " isVertical: " +isVertical+ " isHorisontal: " +isHorisontal ,
          value: 2 //ören i kostnad
        });
        this.getAudio(dataUrl,point,(endPoint?"all":null),isSwipe) 
      } else {
        console.log("Not sending request. PendingRequests: " + this.state.pendingRequests + " playingSound: " + this.state.playingSound +" audioUnlocked: " + this.state.audioUnlocked+" mode: " + this.state.mode)
        this.setState({markedPoint:null})
      }

      function cover(color,x,y,width,height) {
        console.log("In cover",x,y,width,height);
        var ctx = canvas.getContext("2d");
        ctx.beginPath();
        ctx.rect(0, 0, canvas.width, y);
        ctx.fillStyle = color;
        ctx.fill();

        ctx.beginPath();
        ctx.rect(0, y+height, canvas.width, canvas.height-y-height);
        ctx.fill();

        ctx.beginPath();
        ctx.rect(0, 0,x, canvas.height);
        ctx.fill();

        ctx.beginPath();
        ctx.rect(x+width, 0, canvas.width-x-width, canvas.height);
        ctx.fill();
      }
    }

    parseAnnotations(annotations,point){ 
      console.log(annotations)
      var pointedText = TextDetectionParser.getTextAbovePoint(annotations,point)
      console.log("parsing text. Point: " + point.x +',' + point.y    )
      console.log("parsing text. Text: " + pointedText)

    }
    markTextOnCanvas(finalLines, lineMarks,pauseTime){
      if(!pauseTime){
        pauseTime = 0;
      }
      var that = this;
      var lineMarkTimers = [];
      for (let index = 0; index < lineMarks.length; index++) {
        const lineMark = lineMarks[index];
        if(lineMark.timeSeconds - pauseTime > 0){
          lineMarkTimers.push(window.setTimeout(function(){
            that.setState({markedText:[finalLines[index].boundingBox]})
            }, lineMark.timeSeconds*1000 - pauseTime*1000))
        }  
      }
      that.setState({lineMarkTimers:lineMarkTimers})
      if(finalLines && finalLines.length > 0){
        //this.setState({markedText:finalLines.map(line =>line.boundingBox)});
      } else {
        this.setState({markedText:[]});
        if(window.api){
          window.api.send("normalWindow",[])
        }
      }
      console.log("bb setting state markedText to ", this.state.markedText)
    }

    async getUserData(bypassCache) {
      var authSession = await Auth.currentSession();
      var language = navigator.language || navigator.userLanguage;
      var isStripeSuccessPage = (window.location.hash==="#SubscriptionSuccess");
      console.log("isStripeSuccessPage",isStripeSuccessPage);
      //only bypass the cache when coming back from successful subscription
      var user = await Auth.currentAuthenticatedUser({ bypassCache: bypassCache||isStripeSuccessPage });
      const trialUntilString = authSession.getIdToken().payload["custom:trialUntil"];
      console.log("payload",authSession.getIdToken().payload)
      const firstSubscriptionString = authSession.getIdToken().payload["custom:firstSubDate"];
      const activeSubscription = authSession.getIdToken().payload["custom:activeSubscription"];
      var trialUntil = isNaN(Date.parse(trialUntilString))?null:Date.parse(trialUntilString);
      // the very first time before any backend call the trialUntil is empty
      var isFirstVisit = !trialUntil;
      console.log("trialUntilString",trialUntilString);
      console.log("user",user)
      var userIsEligable = activeSubscription === "1" || trialUntil > Date.now()||isFirstVisit;
      var freeTrialEnded = !isFirstVisit&&trialUntil < Date.now() && !firstSubscriptionString;
      var userEmail = user.attributes["email"];
      var userId = user.username;
      if (userIsEligable) {
        //user is eligable
        console.log("User is eligable for an API call");
      }
      else {
        console.log("User is NOT eligable for an API call, triggering purchase screen");
      }
      this.setState({ authSession:authSession,trialUntil:trialUntil,language:language,userEmail:userEmail, userId:userId, activeSubscription:activeSubscription==='1',userIsEligable:userIsEligable, freeTrialEnded:freeTrialEnded})
      return trialUntil
    }
    getNarrative(response,isSwipe,mode,trialUntil,activeSubscription){
      
      var isFirstRead = !localStorage.getItem('hasRead');
      var hasDoneSwipeAndTap = ((localStorage.getItem('hasReadSwipe')=== 'true'&&!isSwipe)||(localStorage.getItem('hasReadTap')=== 'true'&&isSwipe));
      var hasHeardSwipeAndTap = localStorage.getItem('hasHeardSwipeAndTap') ==='true';
      var numberOfReads = localStorage.getItem("numberOfReads")?parseInt(localStorage.getItem("numberOfReads")):0;
      console.log("Getting narrative",response,isSwipe,mode,trialUntil,activeSubscription,isFirstRead,hasDoneSwipeAndTap,hasHeardSwipeAndTap,numberOfReads)

      if(!response.foundText){
        return null;
      }
      /* if(trialUntil&&trialUntil<Date.parse('2021-11-21')){
        // people that used the app before narration was released should not get narration
        return null;
      } */
      //no narrative for screen and tripod mode so far
      if(window.api||mode==='tripod'){
        return null;
      }
      if(isFirstRead&&!isSwipe){
        return "Great Job! You just red your first text. Now try to read multiple paragraphs by swiping over them.";
      }
      if(isFirstRead&&isSwipe){
        return "Great Job! You just red your first text. Swiping over the text will read multiple paragraphs. Now try just tapping under the text. That will read just one paragraph.";
      }
      if(!isFirstRead&&!hasHeardSwipeAndTap&&hasDoneSwipeAndTap){
        localStorage.setItem("hasHeardSwipeAndTap",'true');
        return "Perfect! Now you know how to quickly read either a single paragraph or many. Did you know that ”I can Read” can read over 50 languages? Find a text that is not in your native language and give it a try!";
      }

      //this is the fifth read
      if(numberOfReads===4){
        return "Looks like you are getting the hang of it now. Remember that you can also use ”I can Read” on your computer. Then it will read any text on your screen just as simple as here on your mobile. We sent you an email that describes how to use the desktop mode. Give it a try!";
      }

    }
    async getAudio(dataUrl,point,rowSelectionMode,isSwipe) {
      var startTime = Date.now();
      var that = this;
      this.setState({pendingRequests:this.state.pendingRequests+1});
      await this.getUserData();
      getAudioFromBackend(dataUrl,point,that.state.speed,(rowSelectionMode?rowSelectionMode:that.state.rowSelectionMode),that.state.userEmail,that.state.userId,this.state.authSession,
        function(response, textStatus, xhr) {
          try{
            var responseTime = Date.now();
            console.log("Response of request. time: " + (responseTime - startTime) + 'ms');
            console.log('Response: ', response);
            if(document.querySelector(".DarkScreen")&&that.state.showDarkScreen){
              document.querySelector(".DarkScreen").style.display = "block";
            }
            // Conversion successful.
            if(!that.state.markedPoint){
              // no marked point so we shall not read
              console.log("pendingRequests (no marked point)",that.state.pendingRequests);
              that.setState({pendingRequests:that.state.pendingRequests-1});
              console.log("pendingRequests (no marked point)",that.state.pendingRequests);
              that.setState({repaintCrosshair:true});
              that.setState({repaintCrosshair:false});
              return
            }
 
            if (response.status_code === 200 ) {
              var text = response.text;
              //if freeTrialStarted 
              if(response.freeTrialStarted||(!that.state.userIsEligable)){
                console.log("FreeTrailStared or user was not eligable but backend call was fine, refreshing user to get the new values");
                that.getUserData(true);
              }
              console.log(text);
              console.log(response.textAnnotations);
              if(text!=="no text found"&&that.state.pendingRequests<2){

                const base64Audio = response.audio;
                that.setState({finalLines:response.finalLines,
                  lineMarks:response.lineMarks,words:response.words})
                console.log("pendingRequests (play)",that.state.pendingRequests);
                that.setState({pendingRequests:that.state.pendingRequests-1});
                console.log("pendingRequests (play)",that.state.pendingRequests);
                //that.parseAnnotations(response.textAnnotations, point)
                const binaryAudio = Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0));
                console.log("binarydata prepared")
                console.log(that)
                that.state.audioContext.decodeAudioData(binaryAudio.buffer,function(buffer){
                  try{
                    console.log("binarydata decoded");
                    var narrative;
                    // make it easy to override the getNarrative in an A/B test.
                    narrative = window.getNarrative?window.getNarrative(response,isSwipe,that.state.mode,that.state.trialUntil,that.state.activeSubscription):that.getNarrative(response,isSwipe,that.state.mode,that.state.trialUntil,that.state.activeSubscription);
                    console.log("using Narrative",narrative)
                    that.play(buffer,narrative);
                    if(narrative){
                      ReactGA.event({
                        category: 'Narrative',
                        action: 'Play',
                        label: narrative
                      });
                    }
                    ReactGA.event({
                      category: 'Read',
                      action: (response.foundText?"PlaySound":"NoTextFound"),
                      label: !response.foundText?"Cached: " + response.cachedAudio +", "+ text:"ShowWords: " + that.state.showWords + "TextLocale: " + response.locale + ", BrowserLocale: " + (navigator.language || navigator.userLanguage) + ", Text: "+text,
                      value: Math.round(text.length * 0.016) //ören i kostnad
                    });
                    var d = new Date()
                    var timing = d.getTime() - that.state.lastFrameTime;
                    console.log( 'this.state.lastFrameTime: '+ that.state.lastFrameTime)
                    console.log('timing: ' + timing);
                    if(response.foundText){
                      localStorage.setItem("hasRead","true");
                      localStorage.setItem(isSwipe?"hasReadSwipe":"hasReadTap","true");
                      localStorage.setItem("numberOfReads",localStorage.getItem("numberOfReads")?parseInt(localStorage.getItem("numberOfReads"))+1:1);
                    }
                    if(response.foundText){
                      that.getUserData().then((trialUntil) => {
                        trackPlayedSound(that.state.mode,response.locale,navigator.language || navigator.userLanguage,text.trim().split(/\s+/).length,trialUntil,that.state.userEmail?that.state.userEmail:localStorage.getItem('user'))
                      })
                    }
                    ReactGA.event({
                      category: 'ReadTiming',
                      action: 'BeepToRead',
                      value: timing, // in milliseconds
                      label: !response.foundText?"Cached: " + response.cachedAudio +", "+ text:text
                    });
                  } catch (err){
                    that.handleError(err);
                  }  
                },
                function(err){ 
                  that.handleError(err);
                }
                );
              } else {
                console.log("pendingRequests (no play)",that.state.pendingRequests);
                that.setState({pendingRequests:that.state.pendingRequests-1});
                console.log("pendingRequests (no play)",that.state.pendingRequests);  
                if(that.state.pendingRequests<1){
                  //only remove point if there are no pending requests
                  that.setState({markedPoint:null})
                  if(window.api){
                    window.api.send("normalWindow",[])
                  }
                }
              }
            } else {
              // access denied due to a canceled subscription
              if(response.status_code === 403){
                console.log("Subscription canceled, refreshing user to get the new values so the subscribe module will be shown on next getFrame")
                that.getUserData(true);
              }
              that.setState({pendingRequests:that.state.pendingRequests-1});
              that.setState({markedPoint:null})
            }
        } catch(err){
          that.handleError(err);
        } 
        },function(xhr, textStatus, err) {
          that.handleError(err);
          that.setState({text:textStatus});
          console.log('Error' , err);
          console.log('Error text: ' + textStatus);
        })
		}

    onPageClick(){
      this.setState({rowSelectionMode:'all'});
      localStorage.setItem("rowSelectionMode","all");

      ReactGA.event({
        category: 'RowSelectionMode',
        action: 'ClickedPage'
      });
      return true;
    }
    onParagraphClick(){
      this.setState({rowSelectionMode:'paragraph'});
      localStorage.setItem("rowSelectionMode","paragraph");
      ReactGA.event({
        category: 'RowSelectionMode',
        action: 'ClickedParagraph'
      });
      return true;
    }
    async onTripodClick(){
      this.setState({showSubscribeModule:false});
      if(!this.state.audioUnlocked){
        this.unLockWebAudio();
        this.resetReloadTimer();
        await this.getUserData();
      }
      
      if(this.state.mode==='tripod'){
        this.setState({mode:'none'});
      } else {
        this.setState({mode:'tripod'});
        this.setState({lastMode:'tripod'});
        localStorage.setItem("lastMode","tripod");
      }

      ReactGA.event({
        category: 'Start',
        action: 'Clicked' + this.state.mode + 'Start'
      });
      return true;
    }
   async onHandheldClick(){
      this.setState({showSubscribeModule:false});
      if(!this.state.audioUnlocked){
        this.unLockWebAudio();
        this.resetReloadTimer();
        await this.getUserData();
      }
      if(this.state.mode==='handheld'){
        this.setState({mode:'none'});
      } else {
        this.setState({mode:'handheld'});
        this.setState({lastMode:'handheld'});
        localStorage.setItem("lastMode","handheld");

      }

      ReactGA.event({
        category: 'Start',
        action: 'Clicked' + this.state.mode + 'Start'
      });
      return true;
    }
    resetReloadTimer(){
      if(this.reloadTimer){
        clearTimeout(this.reloadTimer)
        console.log("cleraring the previous reloadTimer")
      }
      this.reloadTimer = setTimeout(() =>{
        console.log("reloading page in reloadTimer")
        window.location.reload()
      },30*60*1000)
      //reload the page every 30 minutes when idle to prevent any memory leaks
    }
    onPlayClick(event,mode){
      if(!this.state.audioUnlocked){
        this.unLockWebAudio();
        this.resetReloadTimer();
  
      } 
      if(mode){
        this.setState({mode:mode});
      } else if(!this.state.mode==='none'){
        this.setState({mode:'none'});
      } else {
        this.setState({mode:this.state.lastMode});
      }
      this.setState({hasWebcamAccess:undefined})

      ReactGA.event({
        category: 'Start',
        action: 'Clicked' + mode?mode:this.state.mode + 'Start'
      });
      console.log("Starting mode",mode?mode:this.state.mode)
      return true;
    
    }    
  onSpeedUpdate(value){
    console.log("setting speed to",value)
    this.setState({speed:value});
    localStorage.setItem("speed",value);

  }
  onShowWordsUpdate(value){
    console.log("setting showWords to",value)
    this.setState({showWords:value});
    localStorage.setItem("showWords",value);

  }
  onScreenMouseSelectModeUpdate(value){
    console.log("setting ScreenMouseSelectMode to",value)
    this.setState({screenMouseSelectMode:value});
    localStorage.setItem("screenMouseSelectMode",value);

  }
  onMouseSelectStateUpdate(value){
    this.setState({mouseSelectState:value});
  }
  onDarkScreenClick(){
    if(document.querySelector(".DarkScreen")){
      document.querySelector(".DarkScreen").style.display = "none";
      this.setState({showDarkScreen:false})
    }
    
  } 
  showTutorial(mode){
    this.setState({showTutorial:true,
                  tutorialMode:mode,
                  mode:'none'})
  }
  onTutorialDone(){
    localStorage.setItem("seenTutorials",true);
    this.setState({showTutorial:false,
                  mode:'none'})
  }

  async onSubscribeClick(){
    await this.getUserData();
    this.setState({showSubscribeModule:true});
  }
  onUnSubscribeClick(){
    trackUnsubscribe(this.state.userId,this.state.userEmail)
  }
  onTrailButtonClick(){
    this.setState({showSubscribeModule:false});
  }
  async onMenuClick(){
    await this.getUserData();
    this.setState({showWords:(localStorage.getItem("showWords")?localStorage.getItem("showWords"):false)})
    this.setState({showSubscribeModule:false});
  }
  onCameraError(){
    this.setState({mode:'none',
                  hasWebcamAccess:false})
  }
  render() {
    if(!localStorage.getItem("hasBeenLoggedIn")){
      localStorage.setItem("hasBeenLoggedIn","1");
    }
    var marginCSS =  `#videoForDisplay,#canvas,#dataImage {
      margin-top: -${window.api?window.api.getTopMargin():0}px;
    }`

    return (
      <div className="TextReader">
        {(window.api?
        <div id="dragBar">
            <style> 
          {marginCSS}
        </style>
        </div>
        
        :"")}
        {!(window.api&&(this.state.markedPoint||this.state.mouseSelectState))
        ?<Menu lastMode={this.state.lastMode}
              mode={this.state.mode}
              onMenuClick={this.onMenuClick.bind(this)}
              lastRowSelectionMode={this.state.rowSelectionMode}
              onPageClick={this.onPageClick.bind(this)} 
              onParagraphClick={this.onParagraphClick.bind(this)}
              onHandheldClick={this.onHandheldClick.bind(this)} 
              onTripodClick={this.onTripodClick.bind(this)}
              onTutorialClick={this.showTutorial.bind(this)} 
              speed={this.state.speed}
              onSpeedUpdate={this.onSpeedUpdate.bind(this)}
              showWords= {this.state.showWords}
              onShowWordsUpdate={this.onShowWordsUpdate.bind(this)}
              screenMouseSelectMode={this.state.screenMouseSelectMode}
              onScreenMouseSelectModeUpdate={this.onScreenMouseSelectModeUpdate.bind(this)}
              onSubscribeClick={this.onSubscribeClick.bind(this)}
              onUnSubscribeClick={this.onUnSubscribeClick.bind(this)}
              defaultLanguage={this.state.language}
              email={this.state.userEmail}
              trialUntil={this.state.trialUntil}
              activeSubscription={this.state.activeSubscription}/> 
              :""}
 
        {// Show subscribe module if the user is not eligable but not if mode is none or if there is a pending request
        //  Show subscribe module if the user clicked it    
        (this.state.showSubscribeModule||(!this.state.userIsEligable&&this.state.mode!=='none'&&this.state.pendingRequests===0)?
        <SubscribeModule onTrailButtonClick={this.onTrailButtonClick.bind(this)} userEmail={this.state.userEmail} userId={this.state.userId} freeTrialEnded={this.state.freeTrialEnded} userCountry={this.state.countryCode}trialUntil={this.state.trialUntil}/>:      
        this.state.mode==='tripod'
        ?<StableFingerTracker predictionThreshhold={0.9} onStablePoint={this.getFrame.bind(this)} onDarkScreenClick={this.onDarkScreenClick.bind(this)} />
        :this.state.mode==='handheld'||this.state.mode==='screen'
        ?<ClickTracker  media={this.state.mode==='screen'?"screen":"camera"} 
                        onClick={this.getFrame.bind(this)} 
                        onSwipe={this.getFrame.bind(this)} 
                        onCameraError={this.onCameraError.bind(this)}
                        onPauseClick={this.pauseSound.bind(this)} 
                        onPlayClick={this.playPausedSound.bind(this)} 
                        words = {this.state.words}
                        showWords = {this.state.showWords}
                        startTime = {this.state.soundStartTime}
                        audioContext={this.state.audioContext}
                        markedText={this.state.markedText} 
                        markedPoint={this.state.markedPoint} 
                        readArea={this.state.readArea}
                        repaintCrosshair={this.state.repaintCrosshair} 
                        readState={this.state.readState}
                        videoHidden={window.api!==undefined}
                        screenMouseSelectMode={this.state.screenMouseSelectMode}
                        onMouseSelectStateUpdate={this.onMouseSelectStateUpdate.bind(this)}/>
        :""
        )}
        {DetectRTC.isMobileDevice||window.api?"":
        <div className="DesktopMessage">
          {I18n.get('desktopMessage')}<a href={'https://www.icanread.io/' + I18n.get('downloadLangcode') + '/desktop'}>{I18n.get('here')}</a>
        </div>}
        {window.api&&(this.state.screenMouseSelectMode===false||this.state.screenMouseSelectMode==="false")?<div className="DesktopMessage">
          {I18n.get('screenModeMessage'+ (DetectRTC.osName.indexOf('Mac')!==-1?"Mac":"Windows"))}
        </div>:""}
        {window.api&&!this.state.mouseSelectState&&(this.state.screenMouseSelectMode===true||this.state.screenMouseSelectMode==="true")?<div className="DesktopMessage">
          {I18n.get('screenModeMessageMouse'+ (DetectRTC.osName.indexOf('Mac')!==-1?"Mac":"Windows"))}
        </div>:""}
        {this.state.mode==='none'&&!this.state.showTutorial
          ?<div className="PlayContainer">
              
              <img alt="Start" src={playbutton} onClick={this.onPlayClick.bind(this)}/>
            </div>   
          :""
        }
        {typeof this.state.hasWebcamAccess !== 'undefined'&&!this.state.hasWebcamAccess
          ?<div className="error">{I18n.get('CameraError1')}<br></br>{I18n.get('CameraError2')}<br></br><br></br>
          {this.state.isIOS?I18n.get('CameraErrorIOS3'):I18n.get('CameraErrorAndroid3')}
          <br></br><br></br> {I18n.get('CameraError4')}</div>
          :""
        }
        {this.state.mode==='none'&&this.state.showTutorial
          ?<Tutorial mode={this.state.tutorialMode} onDone={this.onTutorialDone.bind(this)} onPlayClick={this.onPlayClick.bind(this)} language={this.state.language} onTutorialClick={this.showTutorial.bind(this)} />
          :""
        }
        {
          (this.state.isInWebAppiOS||this.state.isInWebAppChrome||this.state.mode!=='none'||!DetectRTC.isMobileDevice||this.state.showTutorial||this.state.showSubscribeModule)
          ?""
          :<InstallPrompt/>
        }
      </div>
    )
  }


  }
  export default TextReader