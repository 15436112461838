import React, { Component } from 'react';
import {ReactComponent as Phone} from './icons/phone.svg'; 
import {ReactComponent as Tripod} from './icons/tripod.svg'; 
import {ReactComponent as Paragraph} from './icons/paragraph.svg'; 
import {ReactComponent as Page} from './icons/page.svg'; 
import {ReactComponent as MenuIcon} from './icons/menu.svg'; 
import {ReactComponent as Turtle} from './icons/turtle.svg'; 
import {ReactComponent as Rabbit} from './icons/rabbit.svg'; 
import logo from './icons/logo-with-slogan.png'; 
import { I18n } from "aws-amplify";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
         showSubMenu:false
        };
    }
    onHandheldClick(){
        this.setState({showSubMenu:false});
        this.props.onHandheldClick();
    }
    onTripodClick(){
        this.setState({showSubMenu:false});
        this.props.onTripodClick();
    }

    onHandheldTutorialClick(){
        this.setState({showSubMenu:false});
        this.props.onTutorialClick("Handheld");
    }
    onTripodTutorialClick(){
        this.setState({showSubMenu:false});
        this.props.onTutorialClick("Tripod");
    }
    onPageClick(){
        this.setState({showSubMenu:false});
        this.props.onPageClick();
    }
    onParagraphClick(){
        this.setState({showSubMenu:false});
        this.props.onParagraphClick();
    }
    async onMenuClick(){
        await this.props.onMenuClick();
        this.setState({showSubMenu:!this.state.showSubMenu});
    }
    onSubscribeClick(){
        this.setState({showSubMenu:false});
        this.props.onSubscribeClick();
    }
    onSpeedUpdate(event){
        this.props.onSpeedUpdate(event.target.value/10)
    }
    onShowWordsUpdate(event){
        this.props.onShowWordsUpdate(event.target.checked)
    }
    onScreenMouseSelectModeUpdate(event){
        this.props.onScreenMouseSelectModeUpdate(event.target.checked)
    }
    render() {
        return (
        <div className={"Menu" + (this.state.showSubMenu?" Open":"")}>
            <div className="MenuRow">
                <div className="Logo" id="logo"><img src={logo} alt="IcanRead"></img></div>  
                <div className={"IconAndText " + (this.state.showSubMenu ? "Selected" : "Black")} onClick={this.onMenuClick.bind(this)}>
                    <MenuIcon alt="{I18n.get('menu_label')}"></MenuIcon>
                </div>
            </div>
            {this.state.showSubMenu&&
            <div>
                {this.props.mode === "screen"?"":
                <div className="MenuRow right">
                    <div className={"IconAndText " + (this.props.lastMode === "handheld" ? "Selected" : "")} onClick={this.onHandheldClick.bind(this)}>
                        <Phone alt="{I18n.get('handheld_label')}"></Phone>
                        <div>{I18n.get('handheld_label')}</div>
                    </div>
                    <div className={"IconAndText " + (this.props.lastMode === "tripod" ? "Selected" : "")} onClick={this.onTripodClick.bind(this)}>
                        <Tripod alt="{I18n.get('tripod_label')}"></Tripod>
                        <div>{I18n.get('tripod_label')}</div>
                    </div>
                </div> 
                }
                <div className="MenuRow right">
                    <div className={"IconAndText " + (this.props.lastRowSelectionMode === "all" ? "Selected" : "")} onClick={this.onPageClick.bind(this)}>
                        <Page alt="{I18n.get('page_label')}"></Page>
                        <div>{I18n.get('page_label')}</div>
                    </div>
                    <div className={"IconAndText " + (this.props.lastRowSelectionMode === "paragraph" ? "Selected" : "")} onClick={this.onParagraphClick.bind(this)}>
                        <Paragraph alt="{I18n.get('paragraph_label')}"></Paragraph>
                        <div>{I18n.get('paragraph_label')}</div>
                    </div>
                </div>                 
                <div className="MenuRow">
                    <div className="SliderIcon Left">
                        <Turtle alt="{I18n.get('slow_label')}"></Turtle>
                    </div>
                    
                    <div className="Slider" >
                        <input type="range" value={this.props.speed*10} min="5" max="30" onChange={this.onSpeedUpdate.bind(this)}></input>
                    </div>
                    <div className="SliderIcon Right">
                        <Rabbit alt="{I18n.get('fast_label')}"></Rabbit>
                    </div>
                </div>
                <div className="MenuRow">
                    <div className="Row" >
                        <div>{I18n.get('showWords')}</div>
                        <input type="checkbox" checked={this.props.showWords===true||this.props.showWords==="true"} onChange={this.onShowWordsUpdate.bind(this)}></input>
                    </div>  
                </div>
            </div>}
            {this.state.showSubMenu&&this.props.mode === "screen"&&<div className="MenuRow">
                <div className="Row" >
                    <div>{I18n.get('screenMouseSelectMode')}</div>
                    <input type="checkbox" checked={this.props.screenMouseSelectMode===true||this.props.screenMouseSelectMode==="true"} onChange={this.onScreenMouseSelectModeUpdate.bind(this)}></input>
                </div>
            </div>}  
            {this.state.showSubMenu&&<div className="MenuRow">
                <div className="Row" >
                    <div>{this.props.email}</div>
                </div>    
                <div className="Row" >
                    {!this.props.activeSubscription&&this.props.trialUntil?<div>{I18n.get('freeTrialUntil')}</div>:""}
                    {!this.props.activeSubscription&&this.props.trialUntil?<div>{new Date(this.props.trialUntil).toLocaleDateString()}</div>:""}
                    {this.props.activeSubscription?<div>{I18n.get('subscriptionIsActive')}</div>:""}
                    
                    
                </div>
            </div>}
            {this.state.showSubMenu&&!this.props.activeSubscription&&<div className="MenuRow">
                <div className="SubscribeLink">
                    <div className="SubscribeButton" onClick={this.onSubscribeClick.bind(this)}>
                        {I18n.get('selectAPaidPlan')}
                    </div>
                </div>
            </div>}      
            {this.state.showSubMenu&&this.props.mode !== "screen"&&<div className="MenuRow right">
                <div >{I18n.get('watchTutorials')}</div>
            </div>}
            {this.state.showSubMenu&&this.props.mode !== "screen"&&<div className="MenuRow right">
                <div className="Row"  >
                    <div onClick={this.onHandheldTutorialClick.bind(this)}>{I18n.get('handheld_label')}</div>
                    <div onClick={this.onTripodTutorialClick.bind(this)}>{I18n.get('tripod_label')}</div>
                </div>
            </div>}
            {this.state.showSubMenu&&this.props.activeSubscription?
            <div className="MenuRow">
                <div className="SubscribeLink">
                    <a href={'mailto:info@icanread.io?subject=Unsubscribe request for user: '+this.props.email} onClick={this.props.onUnSubscribeClick.bind(this)} >{I18n.get('unsubscribe')}</a>
                </div>
            </div>:""}
        </div>);
    }
}
export default Menu