const minBlockConfidence = 0.7;

export default class TextDetectionParser {
  static getParagraphs(blocks) {
    const texts = [];

    for (const block of blocks) {
      if (block.confidence < minBlockConfidence) {
        continue;
      }
      for(const paragraph of block.paragraphs){
        const text = TextDetectionParser.getParagraphText(paragraph)  
        const bottom = Math.min(paragraph.boundingBox.vertices[0].y,
                                paragraph.boundingBox.vertices[1].y,
                                paragraph.boundingBox.vertices[2].y,
                                paragraph.boundingBox.vertices[3].y)
        const leftMost =  Math.min( paragraph.boundingBox.vertices[0].x,
                                    paragraph.boundingBox.vertices[1].x,
                                    paragraph.boundingBox.vertices[2].x,
                                    paragraph.boundingBox.vertices[3].x)          
        const rightMost =  Math.max(paragraph.boundingBox.vertices[0].x,
                                    paragraph.boundingBox.vertices[1].x,
                                    paragraph.boundingBox.vertices[2].x,
                                    paragraph.boundingBox.vertices[3].x)

        texts.push({text: text,
                    bottom: bottom,
                    leftMost: leftMost,
                    rightMost: rightMost})
      }
    }
    return texts;
  }

  static getParagraphText(paragraph) {
    const words = [];
    var text = paragraph.words.map((word) => {
    return word.symbols.map((letter) => letter.text).join('');
    }).join(' ');
    words.push(text);
    text = words.join(' ').trim();
    return text;
  }

  static processAnnotations(annotations) {
    var  allTexts = []
    for (const page of annotations.pages) {
      const blocks = page.blocks;
      allTexts = allTexts.concat(TextDetectionParser.getParagraphs(blocks))
    }
    return allTexts;
  }
  static getTextAbovePoint(annotations,point){
      var texts = TextDetectionParser.processAnnotations(annotations)
      console.log(texts)
      texts.forEach(text => {
        console.log("parsing text. textObject.text: " + text.text+ " textObject.bottom: " + text.bottom + " point.y: " + point.y + " textObject.leftMost: " +text.leftMost + " textObject.rightMost: " +text.rightMost)
      });

      //sort ascending of the bottom y coordinate
      texts = texts.sort(function(a, b){return a.bottom - b.bottom})
      for (const text of texts ){
        if(text.bottom>point.y && text.leftMost < point.x && text.rightMost > point.x){
            //We return the first paragraph above the point
            return text.text;
        }
      }
  }

}   