import React, { Component } from 'react';
import adapter from 'webrtc-adapter';
import interact from '@interactjs/interact';
import '@interactjs/actions/drag';
import '@interactjs/dev-tools';
import '@interactjs/auto-start';




class VideoWithCanvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef()
        this.videoForDisplayRef = React.createRef()
        this.state = {
           restarting : false
          };
        
    } 
    correctSizes() {
        this.canvasRef.current.width = this.videoForDisplayRef.current.videoWidth;
        this.canvasRef.current.height = this.videoForDisplayRef.current.videoHeight;
        if(window.api){
            this.videoScaleFactor = 1;
            console.log("correcting sizes of VideoWithCanvas",this.canvasRef.current.clientHeight,this.videoForDisplayRef.current.videoHeight)
        } else {
            this.videoScaleFactor = this.videoForDisplayRef.current.clientWidth/this.videoForDisplayRef.current.videoWidth;
            console.log("correcting sizes of VideoWithCanvas",this.videoScaleFactor,this.videoForDisplayRef.current.clientWidth,this.videoForDisplayRef.current.videoWidth)
        }
    
        
        
    }

    paintBoundingBoxes(boundingBoxes){
        var canvas = this.canvasRef.current;
        var ctx = canvas.getContext('2d');
        if(boundingBoxes && boundingBoxes.length > 0){
            boundingBoxes.map(box =>{
                this.paintBoundingBox(ctx, box,"#35CCA9");
            return null
          })
        }
        var dataUrl = canvas.toDataURL('image/png', 0.8);
        var i = new Image(); 
        i.onload = function(){
          var dataImage = document.getElementById("dataImage")||document.createElement("img");
          dataImage.id="dataImage";
          dataImage.src=dataUrl;
          dataImage.style.zIndex=19;
          if(this.props.videoHidden){
              dataImage.className = "FadeToInvisible"
          }
          var parent = canvas.parentElement;
          parent.insertBefore(dataImage,canvas)
        }.bind(this);
        i.src=dataUrl;
      }
  
    paintBoundingBox(ctx, boundingBox,color) {
        //transform hex color to RGB
        const r = parseInt(color.slice(1,3), 16);
        const g = parseInt(color.slice(3,5), 16);
        const b = parseInt(color.slice(5,7), 16);
        //set alpha to 30%
        const a = 0.3
        const transparentColor = 'rgba('+r+', '+g+', '+b+', '+a+')';
        var vertices = boundingBox.vertices.slice()
        ctx.beginPath();  
        ctx.lineWidth = "1";
        ctx.strokeStyle = transparentColor;
        ctx.fillStyle = transparentColor;
        var point = vertices.pop();
        ctx.moveTo(point.x, point.y);
        point = vertices.pop();
        ctx.lineTo(point.x, point.y);
        point = vertices.pop();
        ctx.lineTo(point.x, point.y);
        point = vertices.pop();
        ctx.lineTo(point.x, point.y);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
    }
    paintCrossHair(x,y){
        var canvas = this.canvasRef.current;
        var ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.strokeStyle = "#35CCA9";
        ctx.moveTo(this.startPos?this.startPos.x:0,y);
        ctx.lineTo(this.endPos?this.endPos.x:canvas.width,y);
        ctx.moveTo(x,this.startPos?this.startPos.y:0);
        ctx.lineTo(x,y);
        ctx.stroke();
        console.log("cross hair painted on canvas");
}

    clearCanvas(){
        var canvas = this.canvasRef.current;
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if(document.getElementById("dataImage")){
            document.getElementById("dataImage").style.zIndex=1;
            console.log("Hiding dataImage")
            document.getElementById("dataImage").src="";
        }    
        console.log("clearing canvas")
        this.startPos =null;
        this.endPos = null;
    }
    
    checkOrientation(){
        if(this.state.restarting){
            // already shown an alert
            return
        }
        switch (window.orientation) {  
        case 0:
            this.setState({restarting:true});
            alert("Changing to portrait mode. Restarting...")
            window.location.reload()
            // Portrait 
            break; 
            
        case 180:  
        this.setState({restarting:true});
            alert("Please hold your phone upright. Restarting...")
            window.location.reload()
            break; 
        
        case -90:  
            this.setState({restarting:true});
            // Landscape (Clockwise)
            alert("Changing to landscape mode. Restarting...")
            window.location.reload()
            break;  
        
        case 90:  
            this.setState({restarting:true});
            // Landscape  (Counterclockwise)
            alert("Changing to landscape mode. Restarting...")
            window.location.reload()    
            break;
            
        default:
            return    
        }

    }
    updateBoundingBoxes(prevProps){
        console.log("bb In updateBoundingBoxes of VideoWithCanvas");
        if (prevProps.boundingBoxes && prevProps.boundingBoxes.length > 0 && this.props.boundingBoxes && this.props.boundingBoxes.length ===0 ) {
            // the new props have no boundingboxes but the old did
            console.log("bb the new props have no boundingboxes but the old did, clearCanvas");
            this.clearCanvas();
        } else if(prevProps.boundingBoxes && prevProps.boundingBoxes.length ===0 && this.props.boundingBoxes && this.props.boundingBoxes.length>0){
            // new props have boundingboxes but the old did not
            console.log("new props have boundingboxes but the old did not");
            this.paintBoundingBoxes(this.props.boundingBoxes);    
        } else if(prevProps.boundingBoxes && this.props.boundingBoxes && prevProps.boundingBoxes.length !== this.props.boundingBoxes.length){
            // new props have boundingboxes but they are not the same
            console.log("bb new props have boundingboxes but they are not the same. clearCanvas and paint boundingbox prevProps:", prevProps, "newProps: ", this.props);
            //this.clearCanvas();
            this.paintBoundingBoxes(this.props.boundingBoxes);
        } else if(prevProps.boundingBoxes && this.props.boundingBoxes && prevProps.boundingBoxes.length === this.props.boundingBoxes.length){
            // new props have boundingboxes of same length, we must check of they differ
            console.log("bb new props have boundingboxes of same length, we must check of they differ");
            for (let i = 0; i < prevProps.boundingBoxes.length; i++) {
                const oldBoxVertices = prevProps.boundingBoxes[i].vertices.slice();
                const newBoxVertices = this.props.boundingBoxes[i].vertices.slice();
                if(oldBoxVertices[0].x===newBoxVertices[0].x&&
                   oldBoxVertices[0].x===newBoxVertices[0].x&&
                   oldBoxVertices[0].y===newBoxVertices[0].y&&
                   oldBoxVertices[0].y===newBoxVertices[0].y&&
                   oldBoxVertices[1].x===newBoxVertices[1].x&&
                   oldBoxVertices[1].x===newBoxVertices[1].x&&
                   oldBoxVertices[1].y===newBoxVertices[1].y&&
                   oldBoxVertices[1].y===newBoxVertices[1].y&&
                   oldBoxVertices[2].x===newBoxVertices[2].x&&
                   oldBoxVertices[2].x===newBoxVertices[2].x&&
                   oldBoxVertices[2].y===newBoxVertices[2].y&&
                   oldBoxVertices[2].y===newBoxVertices[2].y&&
                   oldBoxVertices[3].x===newBoxVertices[3].x&&
                   oldBoxVertices[3].x===newBoxVertices[3].x&&
                   oldBoxVertices[3].y===newBoxVertices[3].y&&
                   oldBoxVertices[3].y===newBoxVertices[3].y){
                        //all verices in this boundingBox match. do nothing
                } else {
                    //some verice in this boundingBox does not match. redraw the canvas
                    console.log("bb some verice in this boundingBox  does not match. clearCanvas and paint boundingbox");
                    //this.clearCanvas();
                    this.paintBoundingBoxes(this.props.boundingBoxes);
                    break;
                } 
                
            }
            console.log("bb no diffence");
        } else {
            console.log("bb no action prevProps: ", prevProps, " newProps:",this.props);
            
        }
    }
    componentDidUpdate(prevProps) {
        if(window.stream&&!window.stream.active){
            // the stream is no longer active (black screan) restart it
            this.componentDidMount();
        }
        this.updateBoundingBoxes(prevProps)
        console.log("in componentDidUpdate prevProps: ",prevProps,"newProps:", this.props)
        if((this.props.markedPoint&&this.props.repaintCrosshair)||(!prevProps.markedPoint&&this.props.markedPoint)){
            // asked to repaint OR is there now but was not before
            this.paintCrossHair(this.props.markedPoint.x,this.props.markedPoint.y)
        } else if(!this.props.markedPoint&&prevProps.markedPoint){
            // was there before but not now
            console.log("removing crosshair clearCanvas")
            this.clearCanvas();
        } else {
            console.log("no action for crosshair")
        }
      }

    componentDidMount() {
        this.acceptClicks = true;
        // treat swipes smallar than 30 px as clicks
        interact.pointerMoveTolerance(30);
        interact('.draggable').origin('self').styleCursor(false)
        .draggable({
            onstart: function(event){
                if(this.props.screenMouseSelectMode===false||this.props.screenMouseSelectMode==="false"){
                    this.canvasRef.current.getContext('2d').drawImage(this.videoForDisplayRef.current,0,0);
                } 
            }.bind(this),
            onend: function(event) {
                this.startPos = this.scalePoint(event.x0,event.y0);
                this.endPos = this.scalePoint(event.pageX,event.pageY);
                this.onTouchEnd();
                this.acceptClicks = false;
                setTimeout(function(){ this.acceptClicks = true;}.bind(this), 100);
            }.bind(this)
        })
        .on('click',function(event){
            if(!this.touchClick&&this.acceptClicks){
                var {x,y} = this.mousePositionElement(event)
                this.props.onClick(this.scalePoint(x,y));
                this.touchClick = false;
            }
        }.bind(this))
        .on('touchend',function(event){
            if(!this.startPos){
                var {x,y} = this.mousePositionElement(event.changedTouches[0])
                this.props.onClick(this.scalePoint(x,y));
                this.touchClick = true;
            }
        }.bind(this))
        .on('touchstart',function(event){
            this.touchClick = false;
            this.startPos =null;
        }.bind(this))
        //alert("supports pointerEvents " + interact.supportsPointerEvent())

        window.onorientationchange = this.checkOrientation.bind(this);
        console.log("media:",this.props.media)
        if (this.props.media==="camera"&&navigator.mediaDevices && navigator.mediaDevices.getUserMedia ) {
        navigator.mediaDevices
            .getUserMedia({
            audio: false,
            video: {
                    width: { ideal: 1024 },
                    facingMode: 'environment'
                }
            })
            .then(stream => {
            window.stream = stream
            this.videoForDisplayRef.current.srcObject = stream
            stream.onended = this.componentDidMount;
            return new Promise((resolve, _) => {
                this.videoForDisplayRef.current.onloadedmetadata = () => {
                console.log("first video resolved")
                this.correctSizes(); 
                resolve()
                }
            })
            })
            .catch(e =>{
                //try without ideal width (old androids)
                navigator.mediaDevices
                .getUserMedia({
                audio: false,
                video: {
                        facingMode: 'environment'
                    }
                })
                .then(stream => {
                    window.stream = stream
                    this.videoForDisplayRef.current.srcObject = stream
                    stream.onended = this.componentDidMount;
                    return new Promise((resolve, _) => {
                        this.videoForDisplayRef.current.onloadedmetadata = () => {
                        console.log("first video resolved")
                        this.correctSizes(); 
                        resolve()
                        }
                    })
                }).catch(e =>{
                    alert("Cannot get access to camera. ");
                    this.props.onCameraError();
                    window.ga('send', 'event', 'JavaScript', 'error', 'Cannot get access to camera. Message:' + e.message +" Name:"+ e.name  +"Browser:" + adapter.browserDetails.browser +" Version " + adapter.browserDetails.version);
                })
            })
        } else if (this.props.media==="screen"&&navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia){
            if(window.api){
                window.api.receive("shortcutPressed", this.onElectronShortCutPressed.bind(this));
                window.api.receive("fullScreenCompleted", this.onFullScreenCompleted.bind(this));
            }    
            window.navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
                    // has no effect unfortunately electron does not handle this setting.
                    cursor: "never"
                }
            })
            .then(stream => {
            window.stream = stream
            this.videoForDisplayRef.current.srcObject = stream
            stream.onended = this.componentDidMount;
            return new Promise((resolve, _) => {
                this.videoForDisplayRef.current.onloadedmetadata = () => {
                console.log("first video resolved")
                this.correctSizes(); 
                resolve()
                }
            })
            })
        }
        else {
            alert("Cannot get access to camera. Please use the standard browser.")
        }
    }
    scalePoint(x,y) {
        var videoDisplayHeight = this.videoForDisplayRef.current.videoHeight * this.videoScaleFactor;
        var videoDisplayOffsetHeight = (this.canvasRef.current.clientHeight - videoDisplayHeight) / 2;
        var videoForDisplay = this.videoForDisplayRef.current;
        var canvasWidth = this.canvasRef.current.clientWidth;
        return {x:scaleX(x,canvasWidth,videoForDisplay), y:scaleY(y,videoForDisplay)};
        function scaleY(y,videoForDisplay) {
            return (y - videoDisplayOffsetHeight) / videoDisplayHeight * videoForDisplay.videoHeight;
        }

        function scaleX(x,width,videoForDisplay) {
            return x / width * videoForDisplay.videoWidth;
        }
    }

    async onElectronShortCutPressed(point){
        console.log("shortcutPressed", point.x,point.y,this.props.screenMouseSelectMode)
        if(this.props.screenMouseSelectMode===true||this.props.screenMouseSelectMode==="true"){
            if (window.Tawk_API) {
                window.Tawk_API.hideWidget();
                setTimeout( function(){
                window.Tawk_API.showWidget(); 
                }, 10000 );
            }    
            var bodyElement = window.document.querySelector("body");
            bodyElement.style.background= "rgba(255, 255, 255, 0.0)";
            bodyElement.style.display ="none";
            this.props.onMouseSelectStateUpdate(true);
            this.correctSizes();
            await this.canvasRef.current.getContext('2d').drawImage(this.videoForDisplayRef.current,0,-1*(window.api.getTopMargin()-1));

            window.api.send("fullScreenWindow",[])

        }
        
        var x = point.x;
        var y = point.y;
        if(this.props.screenMouseSelectMode===true||this.props.screenMouseSelectMode==="true"){
            // do nothing, just wait for the drag
            console.log("waiting for drag in electron, painting video on canvas");
        } else {
            this.props.onClick({x:x,y:y},null,null,true)
        }
        
    }
    onFullScreenCompleted(){
        //this.videoScaleFactor = this.canvasRef.current.clientHeight/this.canvasRef.current.height;
        console.log("fullScreenCompleted",this.videoForDisplayRef.current.getBoundingClientRect().top,this.canvasRef.current.getBoundingClientRect().top)
        if(this.props.boundingBoxes.length===0&&this.props.markedPoint){
            // remove and add class to reset the animation when the screen becomes visible
            document.getElementById("laser").classList.remove("laser");
            document.getElementById("laser").style.top=(this.props.markedPoint.y+this.canvasRef.current.getBoundingClientRect().top)*this.videoScaleFactor + "px"
            document.getElementById("laser").classList.add("laser");
        }
    }
    // Which HTML element is the target of the event
    mouseTarget(e) {
        var targ;
        if (!e) e = window.event;
        if (e.target) targ = e.target;
        else if (e.srcElement) targ = e.srcElement;
        if (targ.nodeType === 3) // defeat Safari bug
            targ = targ.parentNode;
        return targ;
    }
 
    // Mouse position relative to the document
    // From http://www.quirksmode.org/js/events_properties.html
    mousePositionDocument(e) {
        var posx = 0;
        var posy = 0;
        if (!e) {
            e = window.event;
        }
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        return {
            x : posx,
            y : posy
        };
    }

    // Find out where an element is on the page
    // From http://www.quirksmode.org/js/findpos.html
    findPos(obj) {
        var curleft, curtop = curleft = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj === obj.offsetParent);
        }
        return {
            left : curleft,
            top : curtop
        };
    }
 
    // Mouse position relative to the element
    // not working on IE7 and below
    mousePositionElement(e) {
        var mousePosDoc = this.mousePositionDocument(e);
        var target = this.mouseTarget(e);
        var targetPos = this.findPos(target);
        var posx = mousePosDoc.x - targetPos.left;
        var posy = mousePosDoc.y - targetPos.top;
        return {
            x : posx,
            y : posy
        };
    }
    onClick(clickEvent){
        console.log("click target",clickEvent.nativeEvent.target)
        console.log(clickEvent.nativeEvent);
        //const rect = clickEvent.nativeEvent.target.getBoundingClientRect();
        var relPos = this.mousePositionElement(clickEvent);
        var x = relPos.x;
        var y = relPos.y;   
        console.log("firstX",x);
        console.log("firstY",y);
        console.log("clientWidth",clickEvent.nativeEvent.target.clientWidth);
        console.log("clientHeight",clickEvent.nativeEvent.target.clientHeight);
        console.log("videoWidth",this.videoForDisplayRef.current.videoWidth);
        console.log("videoHeight",this.videoForDisplayRef.current.videoHeight);
        var videoDisplayHeight = this.videoForDisplayRef.current.videoHeight * this.videoScaleFactor;
        var videoDisplayOffsetHeight = (clickEvent.nativeEvent.target.clientHeight - videoDisplayHeight)/2;
        console.log("videoDisplayHeight",videoDisplayHeight);
        console.log("videoDisplayOffsetHeight",videoDisplayOffsetHeight);
        x=x/clickEvent.nativeEvent.target.clientWidth*this.videoForDisplayRef.current.videoWidth;
        y=(y-videoDisplayOffsetHeight)/videoDisplayHeight*this.videoForDisplayRef.current.videoHeight;
        console.log("second X",x);
        console.log("second Y",y);  
        this.props.onClick({x:x,y:y})
        this.startPos= null;
        this.endPos =null;
        this.xPos = null;
    }  
    flipSwipeIfNeeded(startPos,endPos){
        var firstStartPosX = startPos.x;
        var firstStartPosY = startPos.y;
        startPos.x=Math.min(startPos.x,endPos.x);
        startPos.y=Math.min(startPos.y,endPos.y);
        endPos.x=Math.max(firstStartPosX,endPos.x);
        endPos.y=Math.max(firstStartPosY,endPos.y);
    }
    detectHorisontalAndVerticalSwipe(startPos,endPos) {
        console.log("detectHorisontalAndVertical",startPos,endPos);
        //vertical swipe
        if(Math.abs(startPos.x-endPos.x)<80){
            //extend area all the way to left and right
            console.log("vertical swipe",startPos.x-endPos.x)
            startPos.x = 0;
            // start and end are similar, pick end
            this.xPos = endPos.x;
            endPos.x = this.videoForDisplayRef.current.videoWidth;
            return
        }
        //horisontal swipe
        if(Math.abs(startPos.y-endPos.y)<50){
            //extend area to top of screen
            console.log("horisontal swipe",startPos.y-endPos.y)
            startPos.y = 0;
            return
        }

    }
    onTouchEnd(touchEvent){
        this.xPos = null;
        if(this.startPos&&this.endPos){
            this.flipSwipeIfNeeded(this.startPos,this.endPos);
            this.detectHorisontalAndVerticalSwipe(this.startPos,this.endPos,this.xPos)
            this.props.onSwipe(this.startPos,this.endPos,this.xPos,this.props.media==="screen")
            this.xPos = null;
        } 
        this.props.onMouseSelectStateUpdate(false);
    }  
    render() {
        //make video fullscreen in landscape mode
        var style = {};
        var laserStyle = {};
        if(window.orientation===90||window.orientation===-90){
            style = {'top':'0px'}
            laserStyle = {'position':'absolute'}
        }
        return (
        <div>
            {   (this.props.boundingBoxes.length===0&&this.props.markedPoint? 
                <div className="laser" id="laser" style={{...{'top':(window.api?this.props.markedPoint.y:this.props.markedPoint.y*this.videoScaleFactor),'left':(this.props.markedPoint.x/this.videoForDisplayRef.current.videoWidth*100) +'%' },...laserStyle}}></div>:""
            )}
            <canvas
            className={`draggable size ${this.props.videoHidden?"FadeToInvisible":""}`}
            ref={this.canvasRef}
            width="100"
            id="canvas"
            style={{...{'zIndex':'100'},...style}}
            />
            <video
            className={`size ${this.props.videoHidden?"Invisible":""}`}
            autoPlay
            playsInline
            muted
            ref={this.videoForDisplayRef}
            onClick={this.props.electronShortcutMode==='drag'?null:this.onClick.bind(this)}
            id="videoForDisplay"
            style={style}
            />
            
        </div>
        )
    }
}
export default VideoWithCanvas

