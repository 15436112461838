import React from 'react';
import VideoWithCanvas from './VideoWithCanvas';
import playButton from './icons/play-button.svg';
import pauseButton from './icons/pause-button.svg';
import FastReadingDisplay from './FastReadingDisplay'





class ClickTracker extends React.Component {
    

    onClick(coordinate){
        console.log("X:",coordinate.x,"Y:",coordinate.y)
        this.props.onClick(coordinate)
    }
    onPlayClick(){
        console.log("PlayClick");
        this.props.onPlayClick();
    }
    onPauseClick(){
        console.log("PauseClick");
        this.props.onPauseClick();
    }

    render(){

        return (
            <div>
                <VideoWithCanvas    onClick={this.props.onClick} 
                                    readArea={this.props.readArea} 
                                    onSwipe={this.props.onSwipe} 
                                    onCameraError={this.props.onCameraError}
                                    boundingBoxes={this.props.markedText} 
                                    markedPoint={this.props.markedPoint} 
                                    videoHidden={this.props.videoHidden} 
                                    repaintCrosshair={this.props.repaintCrosshair} 
                                    media={this.props.media} 
                                    screenMouseSelectMode={this.props.screenMouseSelectMode}
                                    onMouseSelectStateUpdate={this.props.onMouseSelectStateUpdate}/>
                {(this.props.showWords===true||this.props.showWords==="true")&&(this.props.readState==='paused'||this.props.readState==='playing')&&this.props.words?
                <FastReadingDisplay words={this.props.words} startTime={this.props.startTime} audioContext={this.props.audioContext} readState={this.props.readState}></FastReadingDisplay>:""}                    
                {this.props.readState==='paused'?
                <div className="PlayPauseContainer">
                    <img alt="Play" src={playButton} onClick={this.onPlayClick.bind(this)}/>
                </div>:""}
                {this.props.readState==='playing'?
                <div className="PlayPauseContainer">
                    <img alt="Pause" src={pauseButton} onClick={this.onPauseClick.bind(this)}/>
                </div>:""}
            </div>
        )
    }
}
export default ClickTracker