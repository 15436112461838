/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_T4Q5tjFra",
    "aws_user_pools_web_client_id": "4ppoa6nc7bsf3asjdotv8fefs6",
    "oauth": {}
};


export default awsmobile;
