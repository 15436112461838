import React, { Component } from 'react';
import FingerTracker from './FingerTracker';


class StableFingerTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.trackerHistory = [];
    this.historyLength=3
  }
  videoReady(){
    console.log("In VideoReady")  ;
    setInterval(this.addPointToHistory.bind(this),100 /this.historyLength)
  }
  addPointToHistory(){
    //console.log("in addPointToHistory");
    var point = this.lastPoint
    if(!point){
      //this.trackerHistory.shift()
      return
    }

    if(this.trackerHistory.length>=this.historyLength){
      this.trackerHistory.shift()
    }
    this.trackerHistory.push(point)
    //console.table(this.trackerHistory)
    
    window.trackerHistory = this.trackerHistory
    this.lastPoint=null;
  }
  getAvgXandY(){
    var sumx = 0;
    var sumy = 0;
    var sumScore =0;
    var avgx = 0;
    var avgy = 0;
    var avgScore =0;
    var pos;

    for (var i = 0; i < this.trackerHistory.length; i++) {
      pos=this.trackerHistory[i];
      sumx = sumx + pos.x;
      sumy = sumy + pos.y;
      sumScore = sumScore + pos.score
    }
    avgx=sumx/this.trackerHistory.length;
    avgy=sumy/this.trackerHistory.length;
    avgScore=sumScore/this.trackerHistory.length;
    return {x:avgx,y:avgy,score:avgScore}
  }
  pointerIsStable(){
    if(this.trackerHistory.length < this.historyLength){
      console.log("trackerHistory.length=" + this.trackerHistory.length)
      return false
    }
    var pos;
 
    var totalDelta = 0;
    const deltaThreshhold = 10;

    var avg =this.getAvgXandY();

    var avgx = avg.x;
    var avgy = avg.y;
  

    //console.log(avgx)
    for (var i = 0; i < this.trackerHistory.length; i++) {
      pos=this.trackerHistory[i];
      var deltax = avgx - pos.x;
      var deltay = avgy - pos.y;
      var delta = Math.sqrt(deltax*deltax + deltay*deltay)
      totalDelta = totalDelta + delta;
    }
    var isStable = (totalDelta/this.trackerHistory.length)<deltaThreshhold;
    //console.log(isStable)
    return isStable;
  }
  onPoint(point){
    this.setState({trackingPoint:point});
    this.lastPoint = point
    var wasStable = this.state.pointerIsStable;
    this.setState({pointerIsStable:this.pointerIsStable()})
    if(!wasStable&&this.state.pointerIsStable){
        console.warn("stable");
        this.props.onStablePoint(this.getAvgXandY())
    } 
  }
  
  render() {
    return (
      <div>
        <div className="DarkScreen" onClick={this.props.onDarkScreenClick}></div>
        <FingerTracker
          onVideoReady={this.videoReady.bind(this)} 
          onPoint={this.onPoint.bind(this)}
          predictionThreshhold={this.props.presictionThreshhold}
        />
      </div>
    );
  }
}

export default StableFingerTracker;
