import React from 'react';
import {FastReadingEngine} from '../helpers/FastReadingEngine';
import { Textfit } from 'react-textfit';





class FastReadingDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.leftRef = React.createRef();
        this.middleRef = React.createRef();
        this.rightRef = React.createRef();
        this.maxFontSize = 70;
        this.state = {
            currentIndex: 0,
            currentWord:null,
            left:"_",
            middle:"_",
            right:"_"
        }
    } 
    
    
    
    async wordPartsToScreen(wordParts){
        this.setState({
            left:wordParts[0],
            middle:wordParts[1],
            right:wordParts[2]
        })
    }
  
    componentWillUnmount(){
        console.log("FastReadingDisplay in componentWillUnmount",this.myReq)
        window.cancelAnimationFrame(this.myReq)
    }
    componentDidMount(){
        this.frn = new FastReadingEngine();
        this.myReq = window.requestAnimationFrame(this.refresh.bind(this));
        this.setState({ 
            currentIndex: 0
        })
    }
    refresh(){
        var currentTime = this.props.audioContext.currentTime - this.props.startTime;
        //console.log("refresh",currentTime*1000,this.state.currentWord,this.state.currentIndex,this.props.words[this.state.currentIndex+1].startTimeMillis);
        if(this.props.words&&this.props.words.length>0&&this.state.currentWord===null&&this.props.words[this.state.currentIndex]){
                this.setState({currentWord:this.props.words[this.state.currentIndex]});
                this.frn.setText(this.props.words[this.state.currentIndex].wordFromEvent);
                this.wordPartsToScreen(this.frn.getWordParts()); 
                //console.log("refresh from null",currentTime,this.props.words[this.state.currentIndex],this.state.currentIndex);
        } else if(this.props.words&&this.props.words.length>this.state.currentIndex&&this.props.words[this.state.currentIndex+1].startTimeMillis<currentTime*1000){
            //the current word should no longer be displayed. find the one that should
            //console.log("refresh finding next word");
            for (let i = this.state.currentIndex + 1; i < this.props.words.length; i++) {
                const word = this.props.words[i];
                //console.log("refresh finding next word",word);
                var nextWord = null;
                if(this.props.words.length>i+1){
                    nextWord = this.props.words[i+1]
                    //console.log("refresh finding next word, nextWord",nextWord);
                }
                if((nextWord&&nextWord.startTimeMillis>currentTime*1000)||(!nextWord&&word.startTimeMillis<currentTime*1000)){
                    //we are witin the time window for word
                    this.setState({ currentIndex:i,
                                    currentWord:word});
                                    this.forceUpdate();                
                    this.frn.setText(word.wordFromEvent);
                    this.wordPartsToScreen(this.frn.getWordParts()); 
                    //console.log("refresh word",currentTime*1000,word.wordFromEvent);
                    break;
                }        
            }
        } else{

        }
        if(this.props.words&&currentTime*1000<this.props.words[this.props.words.length-1].startTimeMillis){
            this.myRec = window.requestAnimationFrame(this.refresh.bind(this));
            //console.log("setting this.myRec",this.myRec,currentTime*1000)
        }
        
        
    }

    setSize(){
       if(this.props.audioContext&&document.getElementById("left")){
            //var currentTime = this.props.audioContext.currentTime - this.props.startTime;
            //console.log("setSize FastReadinDisplay",currentTime,this.state.left + this.state.middle +this.state.right)
        }
         
        if(document.getElementById("right")){
            let compStyles = window.getComputedStyle(document.getElementById("right"));
            let size = compStyles.getPropertyValue('font-size');
            document.getElementById("left").style.fontSize = size;
            document.getElementById("middle").style.fontSize = size;
            //console.log("Changing text size to",size);
        }


    }

    render(){
        if(this.props.audioContext&&document.getElementById("left")){
            //var currentTime = this.props.audioContext.currentTime - this.props.startTime;
            //console.log("render FastReadinDisplay",currentTime,this.state.left + this.state.middle +this.state.right,document.getElementById("right").style)
        }
        if(document.getElementById("right")&&(document.getElementById("right").style===null||document.getElementById("right").style.width===null||document.getElementById("right").style.width==="")){
            // css sets width to 100% but the Textfit cant handle that so setting an explicit width here
            var width = document.getElementById("singleWord").clientWidth;
            document.getElementById("right").style.width = (width-82)/2 + "px";
            //console.log("setting explicit width",width*0.39)
        }
        return (
            <div className="WordReader">
                <div id="singleWord">
                    <div></div>
                    <div className="MidMarker" id="marker1">&nbsp;</div>
                    <div></div>
                    <div ref={this.leftRef} style={{size:this.state.size}} id="left">{this.state.left}</div>
                    <div ref={this.middleRef} style={{size:this.state.size}} id="middle">{this.state.middle}</div>
                    <Textfit mode="single" max={this.maxFontSize} onReady={this.setSize.bind(this)} className="FitContainer"><div id="right">{this.state.right}</div></Textfit>
                    <div></div>
                    <div className="MidMarker" id="marker2">&nbsp;</div>
                    <div></div>
                </div>
            </div>
        )
    }
}
export default FastReadingDisplay