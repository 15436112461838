
export async function getAudioFromBackend(dataUrl,point,speed,rowSelectionMode,userEmail,userId,authSession,onSuccess,onError) {
    var startTime = Date.now();
    var stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
    var url = "";
    switch(stage) {
      case "dev":
        url = "https://0q331f5n0b.execute-api.eu-west-1.amazonaws.com/dev/dataurltotext";
        break;
      case "beta":
        url = "https://zybgmcp8nl.execute-api.eu-west-1.amazonaws.com/beta/dataurltotext";
        break;
      case "prod":
        url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/dataurltotext";
        break;  
      default:
        url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/dataurltotext";
        break;
    };
    var language = navigator.language || navigator.userLanguage
    var preferredLanguageVariants = localStorage.getItem("preferredLanguageVariants");
    console.log("sending request to: " +url);
      window.$.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        crossDomain: 'true',
        headers: { Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}` },
        data: { 'data_url': dataUrl,
                'point': point,
                'speed': speed,
                'language': language,
                'userEmail':userEmail,
                'userId': userId,
                'preferredLanguageVariants':preferredLanguageVariants,
                'rowSelectionMode' : rowSelectionMode,
              },
        complete: function(xhr, textStatus) {
        // Request complete.
        var completedTime = Date.now();
        console.log("request complete. Total time: " + (completedTime - startTime) + 'ms');
        },
        // Request was successful.
        success: onSuccess,
        error: onError
      });
      console.log(authSession.getIdToken().payload);
 }

export async function getNarrationAudioFromBackend(text,locale,onSuccess,onError) {
  var startTime = Date.now();
  var stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
  var url = "";
  switch(stage) {
    case "dev":
      url = "https://0q331f5n0b.execute-api.eu-west-1.amazonaws.com/dev/reader";
      break;
    case "beta":
      url = "https://zybgmcp8nl.execute-api.eu-west-1.amazonaws.com/beta/reader";
      break;
    case "prod":
      url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/reader";
      break;  
    default:
      url = "https://qjwnbvso84.execute-api.eu-west-1.amazonaws.com/prod/reader";
      break;
  };
  var language = navigator.language || navigator.userLanguage
  console.log("sending request to: " +url);
    window.$.ajax({
      url: url + "?text=" + text,
      type: 'GET',
      dataType: 'json',
      crossDomain: 'true',
      data: { 
        'text': text, 
        'speed': 1,
        'userLanguage': language,
        'locale': 'en-US'
      },
      complete: function(xhr, textStatus) {
      // Request complete.
      var completedTime = Date.now();
      console.log("request complete. Total time: " + (completedTime - startTime) + 'ms');
      },
      // Request was successful.
      success: onSuccess,
      error: onError
    });
}      