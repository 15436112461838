import React, { Component } from 'react';
import { I18n } from "aws-amplify";
import { trackTutorialView } from '../helpers/conversionTracking';
import DetectRTC from 'detectrtc';
import playbutton from './icons/play-button.svg';
import handheld from './icons/handheld.png'
import tripod from './icons/tripod.png'


class Tutorial extends Component {  
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.videos_status = [];
    }
    isIOS(){
        return DetectRTC.osName==='iOS';
    } 
    isAndroid(){
        return DetectRTC.osName==='Android';
    }
    isFacebookBrowser(){
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }
    isInstagramBrowser(){
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("Instagram") > -1);
    }
    componentDidUpdate(){
        this.trackProgress();
    }
    componentDidMount(){
        this.trackProgress();
    }
    getVideoPath(mode,language){
        try {
            if(mode==="None"){
                mode="Handheld"
            }
            switch (language.substring(0,2)) {
                case 'en':
                    
                    return'/videos/' + mode + 'Tutorial_' + language.substring(0,2) + (mode==='Handheld'&&this.isIOS()&&!(this.isFacebookBrowser()||this.isInstagramBrowser())?"_ios":"") + (mode==='Handheld'&&this.isAndroid()&&!(this.isFacebookBrowser()||this.isInstagramBrowser())?"_android":"") + (this.isFacebookBrowser()||this.isInstagramBrowser()?"_facebook":"") + '.mp4'
            // add more case if videos are translated
                default:
                    return'/videos/' + mode + 'Tutorial_en.mp4'
            }
        } catch (error) {
            return'/videos/' + mode + 'Tutorial_en.mp4'
        }
        
       
    } 
    eventHandler(e) {
        switch (e.type) {
            
            // This event type is sent everytime the player updated it's current time, 
            // We're using for the % of the video played. 
        case 'timeupdate':
            // Let's set the save the current player's video time in our status object 
            this.videos_status[e.target.id].current = Math.round(e.target.currentTime);
            
            // We just want to send the percent events once 
            var pct = Math.floor(100 * this.videos_status[e.target.id].current / e.target.duration);
            for (var j in this.videos_status[e.target.id]._progress_markers) {

                if (pct >= j && j > this.videos_status[e.target.id].greatest_marker) {
                    this.videos_status[e.target.id].greatest_marker = j;
                }
            }
            // current bucket hasn't been already sent to GA?, let's push it to GTM
            if (this.videos_status[e.target.id].greatest_marker && !this.videos_status[e.target.id]._progress_markers[this.videos_status[e.target.id].greatest_marker]) {
                this.videos_status[e.target.id]._progress_markers[this.videos_status[e.target.id].greatest_marker] = true;
                trackTutorialView("progress",this.videos_status[e.target.id].greatest_marker,decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]))
            }
            break;
            // This event is fired when user's click on the play button
        case 'play':
            trackTutorialView("play",this.videos_status[e.target.id].current,decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]))
            break;
            // This event is fied when user's click on the pause button
        case 'pause':
            trackTutorialView("pause",this.videos_status[e.target.id].current,decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]))
            break;
            // If the user ends playing the video, an Finish video will be pushed ( This equals to % played = 100 )  
        case 'ended':
            trackTutorialView("complete",100,decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]))
            console.log("video ended calling onDone")
            this.props.onDone();
            break;
        default:
            break;
        }
    }
    trackProgress(){
        // This is gonna our percent buckets ( 25%-75% ) 
        var divisor = 25;
        if(!this.videoRef.current){
            // no video so just return
            return
        }
        // We need to configure the listeners
        // Let's grab all the the "video" objects on the current page     
        var videos = [this.videoRef.current]
        for (var i = 0; i < videos.length; i++) {
            // In order to have some id to reference in our status object, we are adding an id to the video objects
            // that don't have an id attribute. 
            var videoTagId;

            videoTagId = videos[i].getAttribute('id');
        
            // Video Status Object declaration  
            this.videos_status[videoTagId] = {};
            // We'll save the highest percent mark played by the user in the current video.
            this.videos_status[videoTagId].greatest_marker = 0;
            // Let's set the progress markers, so we can know afterwards which ones have been already sent.
            this.videos_status[videoTagId]._progress_markers = {};
            for (var j = 0; j < 100; j++) {
                this.videos_status[videoTagId].progress_point = divisor * Math.floor(j / divisor);
                this.videos_status[videoTagId]._progress_markers[this.videos_status[videoTagId].progress_point] = false;
            }
            // On page DOM, all players currentTime is 0 
            this.videos_status[videoTagId].current = 0;
            // Now we're setting the event listeners. 
            videos[i].addEventListener("play", this.eventHandler.bind(this), false);
            videos[i].addEventListener("pause", this.eventHandler.bind(this), false);
            videos[i].addEventListener("ended", this.eventHandler.bind(this), false);
            videos[i].addEventListener("timeupdate", this.eventHandler.bind(this), false);
            videos[i].addEventListener("ended", this.eventHandler.bind(this), false);
        }

    }
    onDone(){
        trackTutorialView("doneClick",this.videos_status[this.videoRef.current.id].current,null)
        this.props.onDone()
    }
    onPlayClick(){
        this.props.onPlayClick()
    }

    render() {
        if (!localStorage.getItem("seenTutorials")&&localStorage.getItem("testing_tutorial")==="variant1"){
            return this.renderVariant1();
        }
        return (
            <div className="Tutorial">
            {(this.props.mode==="None"?
            <div className="MenuRow">
                 <div className="Row">
                    {I18n.get('startReadingNow')}
                </div>
                <div className="PlayContainer">
                    <img alt="Start" src={playbutton} onClick={this.onPlayClick.bind(this)}/>
                </div> 
            </div> 
        :<video id={this.getVideoPath(this.props.mode,this.props.language).split("/").pop()} ref={this.videoRef}  autoPlay={this.props.mode !== "None"} src={this.getVideoPath(this.props.mode,this.props.language)} controls >
            </video>)}
            <div className="MenuRow">
                {(this.props.mode==="None"?
                <div className="Row">
                    {I18n.get('learnHowToUse')}
                </div>
                :"")}    
                <div className="Row">
                    <div className={"IconAndText " + (this.props.mode === "Handheld" ? "Selected" : "")} onClick={this.props.onTutorialClick.bind(this,"Handheld")}>
                        <img className="TutorialImage" alt="Handheld" src={handheld} />
                    </div>
                    <div className={"IconAndText " + (this.props.mode === "Tripod" ? "Selected" : "")} onClick={this.props.onTutorialClick.bind(this,"Tripod")}>
                        <img className="TutorialImage" alt="Tripod" src={tripod} />
                    </div>
                </div>
            </div>
            {(this.props.mode==="None"?"":<div className="MenuText MenuRow" onClick={this.onDone.bind(this)}>{I18n.get('startReadingNow')}</div>)}
        </div>
           );
    }
    renderVariant1(){
       return ( 
        <div className="Tutorial">
        {(this.props.mode==="None"?
        <div className="MenuRow">
             <div className="Row">
                {I18n.get('startReadingNow')}
            </div>
            <div className="PlayContainer">
                <img alt="Start" src={playbutton} onClick={this.onPlayClick.bind(this)}/>
            </div> 
        </div> 
    :<video id={this.getVideoPath(this.props.mode,this.props.language).split("/").pop()} ref={this.videoRef}  autoPlay={this.props.mode !== "None"} src={this.getVideoPath(this.props.mode,this.props.language)} controls >
        </video>)}
        <div className="MenuRow">
            {(this.props.mode==="None"?
            <div className="Row">
                {I18n.get('learnHowToUse')}
            </div>
            :"")}    
            <div className="Row">
                <div className={"IconAndText " + (this.props.mode === "Handheld" ? "Selected" : "")} onClick={this.props.onTutorialClick.bind(this,"Handheld")}>
                    <img className="TutorialImage" alt="Handheld" src={handheld} />
                </div>
                {(localStorage.getItem("seenTutorials")?
                <div className={"IconAndText " + (this.props.mode === "Tripod" ? "Selected" : "")} onClick={this.props.onTutorialClick.bind(this,"Tripod")}>
                    <img className="TutorialImage" alt="Tripod" src={tripod} />
                </div>
                :"")}
            </div>
        </div>
        {(this.props.mode==="None"?"":<div className="MenuText MenuRow" onClick={this.onDone.bind(this)}>{I18n.get('startReadingNow')}</div>)}
    </div>
            );
    }
}
export default Tutorial